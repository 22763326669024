<template>
  <div class="features-view--wrap main-bg-gradient">
    <v-container class="py-8 py-sm-15">
      <v-sheet rounded="xl" class="pa-8 pa-sm-15 mb-15">
        <hyper-heading
          title="Flexibel ohne Einschränkungen"
          subtitle="Einzigartig am Markt"
          class="mb-8 mb-sm-15"
        />

        <div
          v-for="(category, x) in categories"
          :key="x"
          class="mb-8"
        >
          <h4 class="mb-4 text-h6 font-weight-bold">
            {{ category.title }}
          </h4>

          <v-row>
            <v-col
              v-for="(highlight, i) in category.highlights"
              :key="`${x}_${i}`"
              :cols="12"
              :lg="6"
              class="d-flex"
            >
              <v-card
                class="flex-grow-1 d-flex position-relative"
                rounded="lg"
                color="secondary"
                variant="tonal"
                @click="activeEntry = highlight"
              >
                <v-tooltip text="Mehr erfahren">
                  <template #activator="{ props }">
                    <v-btn
                      icon
                      v-bind="props"
                      size="x-small"
                      flat
                      class="learn-more-button"
                    >
                      <v-icon icon="info" color="primary" size="32" />
                    </v-btn>
                  </template>
                </v-tooltip>
                <v-card
                  width="120"
                  min-height="100"
                  variant="tonal"
                  rounded="0"
                  class="d-none d-sm-flex flex-shrink-0 flex-grow-0 align-center justify-center"
                >
                  <v-avatar color="white" size="50">
                    <v-icon :icon="highlight.icon" size="40" color="primary" />
                  </v-avatar>
                </v-card>
                <div class="px-2 py-2 px-sm-6 py-sm-4">
                  <v-card-title class="text-primary font-weight-bold pb-1">
                    {{ highlight.title }}
                  </v-card-title>
                  <v-card-text class="text-lg text-primary">
                    {{ highlight.description }}
                  </v-card-text>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-sheet>

      <v-dialog
        width="900"
        max-width="90%"
        class="feature-dialog"
        :model-value="activeEntry !== null"
        @update:model-value="isVisible => !isVisible && (activeEntry = null)"
      >
        <v-card v-if="activeEntry">
          <v-card-title class="mt-4 mx-2">
            {{ activeEntry.title }}
          </v-card-title>
          <v-card-text>
            <component :is="activeEntry.modal" />
          </v-card-text>
          <v-card-actions class="px-4 pb-4">
            <v-spacer />
            <v-btn
              append-icon="close"
              variant="elevated"
              flat
              color="primary"
              @click="activeEntry = null"
            >
              Schließen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <learn-more />
    </v-container>
  </div>
</template>

<script>
import { markRaw } from 'vue'

import HyperHeading from '@/components/HyperHeading.vue'
import LearnMore from '@/components/LearnMore.vue'

import LicenceCosts from './FeatureModalContents/LicenceCosts.vue'
import FunctionFlat from './FeatureModalContents/FunctionFlat.vue'
import SipSoftware from './FeatureModalContents/SipSoftware.vue'
import AgfeoSystem from './FeatureModalContents/AgfeoSystem.vue'
import SecurityCloud from './FeatureModalContents/SecurityCloud.vue'
import CommunicationSpecialist from './FeatureModalContents/CommunicationSpecialist.vue'
import SecureByDesign from './FeatureModalContents/SecureByDesign.vue'
import SupportInfo from './FeatureModalContents/SupportInfo.vue'
import MultiDevice from './FeatureModalContents/MultiDevice.vue'
import GroupList from './FeatureModalContents/GroupList.vue'
import CalendarSystem from './FeatureModalContents/CalendarSystem.vue'
import UserProfiles from './FeatureModalContents/UserProfiles.vue'
import CallSettings from './FeatureModalContents/CallSettings.vue'
import PresenceControl from './FeatureModalContents/PresenceControl.vue'
import WelcomeStatistics from './FeatureModalContents/WelcomeStatistics.vue'
import DoorBell from './FeatureModalContents/DoorBell.vue'
import SoftwareApps from './FeatureModalContents/SoftwareApps.vue'
import SummaryInfo from './FeatureModalContents/SummaryInfo.vue'

export default {
  name: 'features-view',

  components: {
    HyperHeading,
    LearnMore,
  },

  data () {
    return {
      activeEntry: null,
      categories: [
        {
          title: 'Die besonderen Vorteile der HyperFonie',
          highlights: [
            {
              title: 'HyperFonie Funktions-FLAT',
              description: 'Vergesst Lizenzen! Die HyperFonie kennt nur „Vollgas“. Es gibt beispielsweise keine Basis, Pro oder Enterprise Ausbaustufen.',
              icon: 'widgets',
              modal: markRaw(FunctionFlat),
            },
            {
              title: 'Einfaches Lizenzmodell, geringe Kosten',
              description: 'Durch das HyperFonie Funktions-Flat Prinzip ist das Lizenzmodell der HyperFonie sehr unkompliziert und überschaubar.',
              icon: 'savings',
              modal: markRaw(LicenceCosts),
            },
            {
              title: 'Kompatibel: >300 SIP Provider, >150 Softwareprodukte',
              description: 'Die HyperFonie ist kompatibel! Eine Portierung Eures Telefonanschlusses ist nicht notwendig und bindet auch gleich noch Eure Branchensoftware ein!',
              icon: 'playlist_add_check',
              modal: markRaw(SipSoftware),
            },
            {
              title: 'AGFEO Systemmerkmale',
              description: 'Herausragende Vorteile unserer mehrfach ausgezeichneten on-premise Systeme in der Cloud nutzen. HyperFonie!',
              icon: 'emoji_events',
              modal: markRaw(AgfeoSystem),
            },
          ]
        },
        {
          title: 'Die Security-Cloud der HyperFonie',
          highlights: [
            {
              title: 'AGFEO Qualitäts-Cloud',
              description: 'Security-Hosting by Design. Made in Bielefeld mit höchster Verfügbarkeit.',
              icon: 'vpn_lock',
              modal: markRaw(SecurityCloud),
            },
            {
              title: 'Die HyperFonie kommt vom Kommunikationsspezialisten',
              description: 'Höchstfunktional, einfachste Bedienung, Erfahrung mit System: AGFEO ist die Nummer 1 für modernste Kommunikation und höchsten Systemkomfort.',
              icon: 'military_tech',
              modal: markRaw(CommunicationSpecialist),
            },
            {
              title: 'Sicherheit Made by Design',
              description: 'Vertrauen Sie uns Ihre Daten und Kommunikation an. Modernste Verfahren bieten ein Höchstmaß an Verfügbarkeit und Sicherheit.',
              icon: 'https',
              modal: markRaw(SecureByDesign),
            },
            {
              title: 'Sichere Betreuung garantiert',
              description: 'Trauen Sie sich individuelle Anpassungen der zahlreichen Funktionsmöglichkeiten zu? Super! Und für alle anderen gilt: Sie sind nicht allein!',
              icon: 'support_agent',
              modal: markRaw(SupportInfo),
            },
          ]
        },
        {
          title: 'Einzigartiger Vorteil: Echter AGFEO Systemkomfort',
          highlights: [
            {
              title: 'CTI/UC/VideoChat-Software für MS Windows, Apple MacOS und Linux',
              description: 'Modernste Unified Communications (UCC) hat einen Namen: AGFEO Dashboard. SoftPhone, VideoChat, perfekte Funktionsbedienung, Visualisierung und mehr.',
              icon: 'laptop_mac',
              modal: markRaw(MultiDevice),
            },
            {
              title: 'Globale, gruppenfähige Anrufliste',
              description: 'Anruflisten, die sich mit allen anderen im Team abgleichen. Damit selbst Rückrufe professionalisiert werden.',
              icon: 'cloud_sync',
              modal: markRaw(GroupList),
            },
            {
              title: 'Kalendersystem: Automatische Steuerungen mit höchster Funktionalität',
              description: 'Geschäftsprozesse brauchen Planbarkeit. Die HyperFonie verfügt sicher über eines der flexibelsten Zeitsteuerungssysteme am Markt.',
              icon: 'today',
              modal: markRaw(CalendarSystem),
            },
            {
              title: 'User-Profile: Individuelle Funktionsanpassungen',
              description: 'So flexibel wie das Leben. Je User bieten die User-Profile der HyperFonie eine äußerst komfortable und sehr flexible Beeinflussung ankommender Gespräche.',
              icon: 'tune',
              modal: markRaw(UserProfiles),
            },
            {
              title: 'Wahlregeln – Abgehende Wahl nach Belieben beeinflussen',
              description: 'Wo andere ein kompliziertes Regelwerk brauchen, verfügt die HyperFonie über unsere komfortabelsten Wahlregeln-Funktionen um abgehende Rufe zu steuern.',
              icon: 'tty',
              modal: markRaw(CallSettings),
            },
            {
              title: 'Präsenzsteuerung: Automatisch',
              description: 'Steuert Eure Anwesenheit nicht nur manuell, sondern lasst Euer Team ganz magisch automatisch an der HyperFonie an- bzw. abmelden.',
              icon: 'phone_missed',
              modal: markRaw(PresenceControl),
            },
            {
              title: 'Wartefelder: Automatische Begrüßung inkl. Statistikdaten',
              description: 'Das Audio-Informations- und Anrufmanagement-System realisiert ganzheitliche Wartefelder.  Begrüßt Anrufer automatisch und lenkt den Anrufer entsprechend.',
              icon: 'auto_graph',
              modal: markRaw(WelcomeStatistics),
            },
            {
              title: 'Türintegration: Mit Komfort',
              description: 'Trotz Cloud höchste Funktionalität auch für Eure Gebäudeinfrastruktur (Türklingeln).',
              icon: 'doorbell',
              modal: markRaw(DoorBell),
            },
            {
              title: 'Speziell integrierte Endgeräte, Software und Apps',
              description: 'Ärgert Ihr Euch auch über die wenigen oder unkomfortable Bedienung Eures Telefons? AGFEO Systemtelefonie macht den Unterschied!',
              icon: 'widgets',
              modal: markRaw(SoftwareApps),
            },
            {
              title: 'EIN FAZIT',
              description: 'Die HyperFonie setzt Maßstäbe. In Funktion, Bedienung, Komfort und persönlicher Betreuung.',
              icon: 'summarize',
              modal: markRaw(SummaryInfo),
            },
          ]
        }
      ],
    }
  },
}
</script>

<style lang="scss">
  .features-view--wrap {
    .v-card-title {
      white-space: normal;
    }

    .learn-more-button {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .feature-dialog {
    ul {
      padding: 0 0 0 16px;

      li {
        padding: 2px 0;
      }
    }
  }
</style>
