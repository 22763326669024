<template>
  <div class="main-hero--wrap">
    <v-sheet
      min-height="600"
      elevation="0"
      class="main-hero--inner is--dark d-flex flex-column"
    >
      <div class="hero-inner flex-grow-1 d-flex align-center">
        <v-container class="d-flex flex-column align-center align-md-start pa-8">
          <v-sheet
            color="transparent"
            class="hero-claim--wrap mb-4 mb-md-10"
            rounded="0"
            elevation="0"
          >
            <h1 class="text-center text-sm-left text-h1 font-weight-bold mb-4 font-exo">
              AGFEO HYPERFONIE
            </h1>
            <h2 class="text-center text-sm-left text-white text-h4 font-exo font-weight-bold">
              Die Cloudtelefonanlage mit System
            </h2>
          </v-sheet>

          <icon-text-flipper class="mx-auto d-md-none mb-8" />

          <v-card
            rounded="lg"
            color="white"
            class="pa-4 price-card text-center text-md-left"
            elevation="0"
            variant="tonal"
          >
            <v-card-title class="text-h5 font-weight-bold">
              Einfach abonnieren oder kombinieren
            </v-card-title>
            <v-card-subtitle class="text-h6 pb-3">
              bereits ab 6,90€ pro Monat/Nutzer
            </v-card-subtitle>
          </v-card>

          <v-hover>
            <template #default="{ isHovering, props }">
              <v-card
                v-bind="props"
                rounded="lg"
                color="white"
                variant="tonal"
                :to="{ name: 'about' }"
                :elevation="isHovering ? 10 : 0"
                class="pa-4 mt-4 d-flex flex-wrap align-center claim-card text-white"
              >
                <agfeo-logo fill="#fff" />
                <v-divider vertical class="ml-4 d-none d-sm-inline-flex" />
                <div class="claim-text--wrap ml-4">
                  <div class="d-none d-sm-block">
                    <div v-if="isHovering">
                      Erfahren Sie mehr über uns und unsere Grundsätze
                    </div>
                    <div v-else>
                      Seit über 75 Jahren der Experte für individuelle Telekommunikations&shy;lösungen.
                    </div>
                  </div>
                  <div class="d-sm-none">
                    Seit über 75 Jahren der Experte für individuelle Telekommunikations&shy;lösungen.
                  </div>
                </div>
              </v-card>
            </template>
          </v-hover>
        </v-container>
      </div>
    </v-sheet>

    <fancy-bg />
  </div>
</template>

<script>
import AgfeoLogo from '@/components/icons/AgfeoLogo.vue'
import FancyBg from '@/components/FancyBg.vue'
import IconTextFlipper from '@/components/IconTextFlipper.vue'

import GLOBE from '@/animations/globe.js'

export default {
  name: 'main-hero',

  components: {
    AgfeoLogo,
    FancyBg,
    IconTextFlipper,
  },

  data () {
    return {
      stripePositionX: 34,
      stripeOffsetX: 0,
    }
  },

  mounted () {
    this.vantaEffect = GLOBE({
      el: '.main-hero--inner',
      mouseControls: true,
      touchControls: false,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      scale: 1,
      scaleMobile: 1.00,
      color: '#fff',
      color2: '#fff',
      size: 2.50,
      showDots: false,
      backgroundAlpha: 0,
    })
  },

  beforeUnmount () {
    if (this.vantaEffect) {
      this.vantaEffect.destroy()
    }
  },
}
</script>

<style lang="scss">
  .main-hero--wrap {
    position: relative;
    z-index: 1;

    .main-hero--inner {
      background: linear-gradient(90deg, #fff, #f6f6f6);
      background-size: 400% 400%;

      -webkit-animation: AnimationName 10s ease infinite;
      -moz-animation: AnimationName 10s ease infinite;
      animation: AnimationName 10s ease infinite;

      &.is--dark {
        background: rgb(var(--v-theme-primary));

        .hero-inner {
          h1, h2, h3 {
            color: rgb(var(--v-theme-primary));
            background: linear-gradient(
              -70deg,
              #fff 0%,
              #ddd 60%,
              #fff 10%
            );
            background-size: 300% auto;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: move-bg 8s infinite linear;
            animation: move-bg 20s infinite linear;
          }

          @keyframes move-bg {
            to {
              background-position: -300% 0;
            }
          }
        }
      }
    }

    .hero-inner {
      h1, h2, h3 {
        color: rgb(var(--v-theme-primary));
        background: linear-gradient(
          -70deg,
          rgb(var(--v-theme-primary)) 0%,
          #002181 60%,
          rgb(var(--v-theme-primary)) 10%
        );
        background-size: 300% auto;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: move-bg 8s infinite linear;
        animation: move-bg 20s infinite linear;
      }

      @keyframes move-bg {
        to {
          background-position: -300% 0;
        }
      }
    }

    @keyframes AnimationName {
      0%{
        background-position: 0% 50%;
      }
      50%{
        background-position: 100% 50%;
      }
      100%{
        background-position: 0% 50%;
      }
    }

    .agfeo-logo--wrap {
      display: flex;
      flex: 1 0 auto;
      max-width: 164px;
      min-width: 120px;
      margin: 8px auto;

      svg {
        flex: 1;
      }
    }

    .price-card {
      max-width: 100%;

      .v-card-title,
      .v-card-subtitle {
        white-space: normal;
      }
    }

    .claim-card {
      width: 467px;
      min-height: 110px;
      max-width: 100%;

      .claim-text--wrap {
        flex: 1;
      }
    }

    @media screen and (min-width: 1280px) {
      .claim-card {
        position: absolute;
        top: 25px;
        right: 40px;
      }
    }
  }
</style>
