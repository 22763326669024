<template>
  <div class="hyper-heading--wrap d-sm-flex text-center text-sm-left align-center">
    <v-img
      src="/images/logo-red-simple.png"
      width="90"
      height="80"
      contain
      alt="HyperFonie"
      class="mr-4 flex-grow-0 d-none d-sm-inline-block"
    />
    <div>
      <slot name="subtitle">
        <h3 class="text-h4 font-weight-light mb-1">
          {{ subtitle }}
        </h3>
      </slot>
      <slot name="title">
        <h2 class="text-h3 font-exo font-weight-bold">
          {{ title }}
        </h2>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'hyper-heading',

  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
