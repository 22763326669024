<template>
  <div>
    <p>
      Die AGFEO HyperFonie punktet auch und gerade im Wettbewerbsumfeld durch
    </p>

    <ul>
      <li>höchste Funktionalität</li>
      <li>besten Komfort</li>
      <li>einfachster Bedienung</li>
      <li>der Unterstützung der AGFEO Systemmerkmale</li>
      <li>ein umfassendes Portfolio aus Systemgeräten</li>
      <li>dem AGFEO Dashboard als multifunktionaler CTI/UC-Client</li>
      <li>die Möglichkeit in den meisten Fällen den SIP Provideranschluss des Kunden weiternutzen zu können</li>
      <li>dem einfachen Lizenzmodell</li>
      <li>der HyperFonie Funktions-Flat</li>
      <li>dem besonders hohen Sparpotential</li>
      <li>und als Besonderheit der direkten Vor-Ort-Betreuung durch einen qualifizierten AGFEO Fachpartner</li>
    </ul>

    <p>
      Und das noch alles zu einem attraktiven User-Preis auf Basis der HyperFonie Funktions-Flat!
    </p>
  </div>
</template>

<script>
export default {
  name: 'summary-info',
}
</script>
