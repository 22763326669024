import { defineStore } from 'pinia'

export const useUiStore = defineStore('ui', {
  persist: true,

  state: () => ({
    isCustomerMode: false,
    showLogin: false,
    showStream: false,
  }),
})
