<template>
  <div>
    <p>
      Mit jeder HyperFonie Installation können immer auch <u>alle</u> möglichen Anlagenfunktionen genutzt werden. Ohne weitere Freischaltung und Kosten!
    </p>

    <ul class="my-4">
      <li>
        Nutzung aller einzigartigen AGFEO Systemmerkmale zzgl.
        <ul>
          <li>Ansage und Wartefeldoptionen (AIS)</li>
          <li>Automatische Zentrale (AMS-Menüauswahl)</li>
          <li>AGFEO Dashboard CTI/UC-Client für MS Windows, MacOS und optional Linux</li>
          <li>SoftPhone Nutzung</li>
          <li>VideoChat/-Konferenz Funktion</li>
          <li>FaxBox und VoiceBox Nutzung</li>
          <li>Integration über AGFEO Klick zu über 150 Softwareprodukten (Inkl. MS Teams, MS Outlook, CRM, ERP, Arztsoftware-Systeme, DATEV und andere Branchenlösungen etc.)</li>
          <li>Anbindung an externe Datenquellen (DATEV, MS Outlook, Google Accounts, ODBC, LDAP)</li>
          <li>etc.</li>
        </ul>
      </li>
    </ul>

    <p>
      Dabei ist die Nutzung idR. nicht nur über unsere Software, sondern auch über die AGFEO Endgeräte möglich. Das bedeutet für Euch: Deutlich MEHR Komfort, MEHR Funktionen und EINFACHERE Bedienung!
    </p>
  </div>
</template>

<script>
export default {
  name: 'function-flat',
}
</script>
