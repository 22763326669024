const AdminIndex = () => import('@/views/Admin/Index.vue')

const CustomerIndex = () => import('@/views/Admin/Customer/Index.vue')
const CustomerDetails = () => import('@/views/Admin/Customer/CustomerDetails.vue')
const CustomerOverview = () => import('@/views/Admin/Customer/CustomerOverview.vue')

const AdministrableInstances = () => import('@/views/Admin/Instance/AdministrableInstances.vue')
const InstanceIndex = () => import('@/views/Admin/Instance/Index.vue')
const InstanceCreation = () => import('@/views/Admin/Instance/InstanceCreation.vue')
const InstanceDetails = () => import('@/views/Admin/Instance/InstanceDetails.vue')
const InstanceOverview = () => import('@/views/Admin/Instance/InstanceOverview.vue')

const DashboardView = () => import('@/views/Admin/DashboardView.vue')
const ImpersonationView = () => import('@/views/Admin/ImpersonationView.vue')
const PartnerDelegateView = () => import('@/views/Admin/PartnerDelegateView.vue')

export default {
  path: '/admin',
  name: 'admin',
  component: AdminIndex,
  redirect: { name: 'dashboard' },
  children: [
    {
      path: 'dashboard',
      name: 'dashboard',
      component: DashboardView,
    },
    {
      path: 'kunden',
      component: CustomerIndex,
      children: [
        {
          path: '',
          name: 'customer-overview',
          component: CustomerOverview,
        },
        {
          path: ':id',
          name: 'customer-details',
          component: CustomerDetails,
        },
      ]
    },
    {
      path: 'instanzen',
      component: InstanceIndex,
      children: [
        {
          path: '',
          name: 'instance-overview',
          component: InstanceOverview,
        },
        {
          path: 'erstellen',
          name: 'instance-creation',
          component: InstanceCreation,
        },
        {
          path: ':id',
          name: 'instance-details',
          component: InstanceDetails,
        }
      ]
    },
    {
      path: 'impersonation',
      name: 'impersonation',
      component: ImpersonationView,
    },
    {
      path: 'stellvertreter',
      name: 'delegates',
      component: PartnerDelegateView,
    },
    {
      path: 'system-administration',
      name: 'system-administration',
      component: AdministrableInstances,
    },
  ],
}
