export default [
  {
    title: 'DATEV',
    description: 'Beschreibung dieser Option',
    value: 'datev',
    price: 30,
  },
  {
    title: 'Premium Support',
    description: 'Beschreibung dieser Option',
    value: 'premium_support',
    price: 70,
  },
  {
    title: 'Tägliches Backup',
    description: 'Speicherdauer: 7 Tage und 1x monatlich',
    value: 'backup',
    price: 10,
  },
]
