import CustomerIndex from '@/views/Customer/Index.vue'
import HomeView from '@/views/Customer/HomeView.vue'
import AboutView from '@/views/Customer/AboutView.vue'
import FaqView from '@/views/Customer/FaqView.vue'
import FeaturesView from '@/views/Customer/FeaturesView.vue'
import ImprintView from '@/views/Customer/ImprintView.vue'
import DataProtectionView from '@/views/Customer/DataProtectionView.vue'

export default {
  path: '/',
  name: 'customer',
  component: CustomerIndex,
  redirect: { name: 'home' },
  children: [
    {
      path: 'start',
      name: 'home',
      component: HomeView,
    },
    {
      path: 'about',
      name: 'about',
      component: AboutView,
    },
    {
      path: 'features',
      name: 'features',
      component: FeaturesView,
    },
    {
      path: 'faq',
      name: 'faq',
      component: FaqView,
    },
    {
      path: 'impressum',
      name: 'imprint',
      component: ImprintView,
    },
    {
      path: 'datenschutz',
      name: 'data-protection',
      component: DataProtectionView,
    },
  ]
}
