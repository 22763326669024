<template>
  <v-app v-if="configLoaded">
    <RouterView />

    <global-snackbar />
  </v-app>
</template>

<script>
import { mapWritableState } from 'pinia'
import { useUiStore } from '@/stores/ui.js'

import Api from '@/api/api.js'
import ConfigApi from '@/api/config.js'
import keycloak from '@/plugins/keycloak.js'

import GlobalSnackbar from '@/components/GlobalSnackbar.vue'

export default {
  components: {
    GlobalSnackbar,
  },

  data () {
    return {
      configLoaded: false,
    }
  },

  computed: {
    ...mapWritableState(useUiStore, ['showLogin', 'showStream']),
  },

  /**
   * Unfortunately, we shouldn't use well-working .env-files for the app-config
   * and must load that from a public json-file. Since the config is required,
   * we can't start the app without it.
   *
   * @returns {Promise}
   */
  async beforeMount () {
    const res = await fetch('/config.json')

    if (!res.ok) {
      return console.error('could not load client-config.')
    }

    const { backend, oidc } = await res.json()
    Api.baseUrl = `${backend.url}api`
    keycloak.config = oidc

    const configRes = await ConfigApi.getUiConfig()

    if (configRes.ok) {
      const config = await configRes.json()
      this.showLogin = config.show_login || false
      this.showStream = config.show_stream || false
    }

    this.configLoaded = true
  },
}
</script>

<style lang="scss">
  @import '@/styles/styles.scss';

  .v-main {
    background-color: #f4f6f9;
  }
</style>
