<template>
  <div>
    <p class="mb-4">
      Damit Ihre Daten ihre Daten bleiben. Das AGFEO Cloud Konzept basiert auf modernsten Verfahren und integriert höchste Sicherheitsstandards.
    </p>

    <p class="mb-4">
      Höchstfunktional, einfachste Bedienung, Erfahrung mit System: AGFEO ist die Nummer 1 für modernste Kommunikation und höchsten Systemkomfort.
    </p>

    <ul>
      <li>Im TÜV zertifizierten Rechenzentrum</li>
      <li>Authentifizierung über OAuth</li>
      <li>Zwei Faktor Authentifizierung bei Bedarf</li>
      <li>Durchgängige Verschlüsselung</li>
      <li>Keine unverschlüsselten Verbindungen möglich</li>
      <li>Integrierte System-Firewall</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'secure-by-design',
}
</script>
