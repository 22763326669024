<template>
  <div
    v-if="showStream"
    class="launch-stream--wrap mb-15"
  >
    <v-card rounded="xl" :elevation="0">
      <iframe
        src="https://www.youtube-nocookie.com/embed/-IessoQMVL8?si=egZ99RUrOPRsLxav"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      />
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useUiStore } from '@/stores/ui.js'

export default {
  name: 'launch-stream',

  computed: {
    ...mapState(useUiStore, ['showStream']),
  },
}
</script>

<style lang="scss">
  .launch-stream--wrap {
    iframe {
      display: block;
      width: 100%;
      aspect-ratio: 16 / 9;
    }
  }
</style>
