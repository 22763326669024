<template>
  <v-dialog
    :model-value="isVisible"
    :loading="isLoading"
    width="1400"
    class="support-search-dialog--wrap"
    @update:model-value="value => !value && $emit('cancel')"
  >
    <v-card :loading="isLoading">
      <v-card-title class="mt-4">
        <div class="d-flex align-center">
          Fachhändler finden

          <v-btn
            icon
            size="small"
            class="ml-auto"
            elevation="0"
            @click="$emit('cancel')"
          >
            <v-icon icon="close" size="28" />
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text class="px-4 mb-4">
        <v-sheet color="grey-lighten-3" class="pa-4 mb-6 mx-n4" rounded="0">
          <v-form
            class="d-flex flex-wrap align-center"
            @submit.prevent="getResults()"
          >
            <v-text-field
              v-model="zip"
              variant="solo"
              flat
              type="number"
              label="Postleitzahl"
              prepend-inner-icon="place"
              density="compact"
              hide-details=""
              class="search-input"
            />
            <v-text-field
              v-model.number="distance"
              variant="solo"
              flat
              type="number"
              label="Umkreis (km)"
              prepend-inner-icon="near_me"
              density="compact"
              hide-details=""
              class="mx-sm-2 mr-auto search-input"
            />
            <v-btn
              :loading="isLoading"
              type="submit"
              color="white"
              rounded="0"
              flat
              size="large"
              class="my-2"
            >
              Suche
            </v-btn>
          </v-form>
        </v-sheet>

        <v-data-table
          v-if="results.length > 0"
          v-model="selectedPartner"
          v-model:items-per-page="itemsPerPage"
          v-model:sort-by="sortBy"
          :headers="headers"
          :items="results"
          item-value="partner_id"
          select-strategy="single"
          return-object
          :show-select="allowChoice"
          multi-sort
          hover
          @click:row="(_, entry) => partnerDetailsToShow = entry.item.raw"
        >
          <template #[`item.partner.email`]="{ item }">
            <a :href="`mailto:${item.raw.partner.email}`" class="text-decoration-none text-primary">
              {{ item.raw.partner.email }}
            </a>
          </template>
          <template #[`item.distance`]="{ item }">
            <div
              v-if="item.raw.distance"
              class="text-right"
            >
              {{ (item.raw.distance / 1000).toFixed(1).replace('.', ',') }}km
            </div>
          </template>
          <template #[`item.partner.stars`]="{ item }">
            <v-icon
              v-for="star in item.raw.partner.stars"
              :key="`${item.raw.partner_id}_${star}`"
              icon="star"
              size="16"
            />
          </template>
        </v-data-table>

        <v-alert
          v-if="postCodeError"
          icon="error"
          color="error"
          rounded="0"
        >
          Ungültige Postleitzahl.
        </v-alert>
      </v-card-text>

      <v-card-actions class="px-4">
        <v-btn
          variant="elevated"
          color="grey-lighten-3"
          elevation="0"
          rounded="0"
          class="px-4"
          @click="$emit('cancel')"
        >
          <v-icon start icon="cancel" />
          {{ allowChoice ? 'Abbrechen' : 'Schließen' }}
        </v-btn>

        <v-btn
          v-if="allowChoice"
          variant="elevated"
          color="primary"
          elevation="0"
          rounded="0"
          class="ml-auto px-4"
          :loading="isLoading"
          @click="$emit('confirm', selectedPartner?.[0]?.partner)"
        >
          <v-icon icon="save" start />
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      :model-value="partnerDetailsToShow !== null"
      width="600"
      max-width="95%"
      class="support-details-dialog"
      @update:model-value="visible => !visible && (partnerDetailsToShow = null)"
    >
      <v-card v-if="partnerDetailsToShow">
        <v-card-title class="px-6 pt-4">
          {{ partnerDetailsToShow.company_name }}
          <div
            v-if="partnerDetailsToShow.company_name2"
            class="text-subtitle-1"
          >
            {{ partnerDetailsToShow.company_name2 }}
          </div>
        </v-card-title>

        <v-card-text>
          {{ partnerDetailsToShow.street }} <br>
          {{ partnerDetailsToShow.post_code }} {{ partnerDetailsToShow.city }}
          <div v-if="partnerDetailsToShow.partner.email">
            <a :href="`mailto:${partnerDetailsToShow.partner.email}`" class="text-decoration-none text-primary">
              {{ partnerDetailsToShow.partner.email }}
            </a>
          </div>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <div class="mx-4">
            <v-icon
              v-for="star in partnerDetailsToShow.partner.stars"
              :key="`${partnerDetailsToShow.partner_id}_${star}`"
              icon="star"
              size="18"
            />
          </div>
          <v-spacer />
          <v-btn
            color="primary"
            @click="partnerDetailsToShow = null"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import PartnerApi from '@/api/partner.js'

export default {
  name: 'support-search-dialog',

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },

    // users are able to select an entry
    allowChoice: {
      type: Boolean,
      default: true,
    },
  },

  emits: [
    'cancel',
    'confirm',
  ],

  data () {
    return {
      isLoading: false,
      results: [],
      itemsPerPage: 10,
      selectedPartner: null,
      distance: 10,
      zip: null,
      partnerDetailsToShow: null,
      postCodeError: false,
      sortBy: [
        { key: 'partner.stars', order: 'desc' },
        { key: 'distance', order: 'asc' }
      ]
    }
  },

  computed: {
    headers () {
      return [
        { title: 'Name', key: 'company_name' },
        { title: 'E-Mail', key: 'partner.email', sortable: false },
        { title: 'Qualifikationen', key: 'partner.stars' },
        { title: 'Entfernung', key: 'distance', align: 'right' },
        { title: 'PLZ', key: 'post_code', align: 'right' },
        { title: 'Stadt', key: 'city' },
        { title: '', key: 'actions', width: 70, sortable: false },
      ]
    },
  },

  methods: {
    async getResults () {
      if (!this.zip || !this.distance || this.distance < 0) {
        return
      }

      this.results = []
      this.isLoading = true
      this.postCodeError = false

      const res = await PartnerApi.searchByDistance(this.zip, this.distance * 1000)
      this.isLoading = false

      if (!res.ok && res.statusText === 'Postcode Not Found') {
        this.postCodeError = true
      }

      if (res.ok) {
        this.results = await res.json()
      }
    },
  },
}
</script>

<style lang="scss">
  .support-search-dialog--wrap {
    thead {
      .v-selection-control {
        display: none;
      }
    }

    .v-field {
      border-radius: 0;
      min-width: 250px;
      max-width: 100%;
    }
  }

  .support-details-dialog {
    .v-card-title {
      white-space: normal;
    }
  }

  @media screen and (max-width: 599px) {
    .support-search-dialog--wrap {
      .search-input {
        width: 100%;
        margin-bottom: 8px;
      }
    }
  }
</style>
