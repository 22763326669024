<template>
  <div class="data-protection-view--wrap main-bg-gradient flex-grow-1">
    <v-container class="py-8 py-sm-15">
      <h1 class="mb-8">
        Datenschutz
      </h1>

      <div>
        <div style="display: inline-block; word-break:break-all; ">
          <h2 class="my-4">
            Inhaltsverzeichnis
          </h2>
        </div>
        <ul style="padding-left: 0 !important; list-style-type: none !important;">
          <li>
            <router-link class="text-primary" :to="{ name: 'data-protection', hash: '#allgemeines' }">
              I. Allgemeines
            </router-link>
          </li>
          <li>
            <router-link class="text-primary" :to="{ name: 'data-protection', hash: '#verantwortliche-stelle' }">
              II. Verantwortliche Stelle
            </router-link>
          </li>
          <li>
            <router-link class="text-primary" :to="{ name: 'data-protection', hash: '#datenschutzbeauftragter' }">
              III. Datenschutzbeauftragter
            </router-link>
          </li>
          <li>
            <router-link class="text-primary" :to="{ name: 'data-protection', hash: '#ihre-rechte' }">
              IV. Ihre Rechte
            </router-link>
          </li>
          <li>
            <router-link class="text-primary" :to="{ name: 'data-protection', hash: '#verarbeitung-personenbezogener-daten-bei-informatorischer-nutzung-unserer-webseite' }">
              V. Verarbeitung personenbezogener Daten bei informatorischer Nutzung unserer Webseite
            </router-link>
          </li>
          <li>
            <router-link class="text-primary" :to="{ name: 'data-protection', hash: '#verarbeitung-personenbezogener-daten-durch-cookies' }">
              VI. Verarbeitung personenbezogener Daten durch Cookies
            </router-link>
          </li>
          <li>
            <router-link class="text-primary" :to="{ name: 'data-protection', hash: '#weitere-funktionen-und-angebote-unserer-webseite' }">
              VII. Weitere Funktionen und Angebote unserer Webseite
            </router-link>
          </li>
          <li>
            <router-link class="text-primary" :to="{ name: 'data-protection', hash: '#kontaktaufnahme' }">
              VIII. Kontaktaufnahme
            </router-link>
          </li>
          <li>
            <router-link class="text-primary" :to="{ name: 'data-protection', hash: '#YouTube' }">
              IX. YouTube
            </router-link>
          </li>
        </ul>
        <h2 id="allgemeines" class="my-4">
          I. Allgemeines
        </h2>
        <p>
          (1) Nachfolgend informieren wir Sie über die Erhebung personenbezogener Daten bei der Nutzung unserer
          Internetseite.
        </p>
        <p>
          (2) Der Begriff 'personenbezogene Daten' meint unter Verweis auf die Definition des Art. 4 Nr. 1 der
          Verordnung (EU) 2016/679 (nachfolgend bezeichnet als 'Datenschutz-Grundverordnung' bzw. kurz 'DSGVO') alle
          Daten, die auf Sie persönlich beziehbar sind. Darunter fallen beispielsweise Name, Adresse, E-Mail-Adresse,
          Nutzerverhalten. Hinsichtlich weiterer Begrifflichkeiten, insbesondere der Begriffe 'Verarbeitung',
          'Verantwortlicher', 'Auftragsverarbeiter' und 'Einwilligung', verweisen wir auf die gesetzlichen
          datenschutzrechtlichen Definitionen des Art. 4 DSGVO.
        </p>
        <p>
          (3) Für Sachverhalte, welche sich in der Schweiz auswirken, auch wenn sie außerhalb der Schweiz veranlasst
          werden, gilt auch das Schweizer Bundesgesetz über den Datenschutz, nachfolgend bezeichnet als 'DSG'. Wir
          verwenden hier jedoch durchgehend die Begriffe der DSGVO. Die Begriffe der DSGVO 'Personenbezogene Daten',
          'Verarbeitung', 'Auftragsverarbeiter', 'besondere Kategorien von Daten' und Datenübertragbarkeit meinen,
          soweit das DSG greift, auch die im DSG verwendeten Begriffe 'Personendaten', 'Bearbeitung',
          'Auftragsbearbeiter', 'Datenübertragung' und 'besonders schützenswerte Personendaten' nach dem DSG. Die
          gesetzliche Bedeutung der Begriffe wird in diesem Fall durch das DSG bestimmt.
        </p>
        <p>
          (4) Wir verarbeiten personenbezogene Daten grundsätzlich nur, soweit dies zur Bereitstellung einer
          funktionsfähigen Webseite sowie der von uns angebotenen Inhalte und Leistungen erforderlich ist. Die
          Verarbeitung personenbezogener Daten erfolgt regelmäßig nur dann, wenn Sie uns eine Einwilligung im Sinne des
          Art. 6 Abs. 1 lit. a) DSGVO erteilt haben oder die Verarbeitung durch gesetzliche Vorschriften, insbesondere
          durch eine der in Art. 6 Abs. 1 lit. b) bis lit. f) DSGVO genannten Rechtsgrundlagen, gestattet ist.
        </p>
        <p>
          (5) Ihre personenbezogenen Daten werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt.
          Eine Speicherung kann darüber hinaus dann erfolgen, wenn dies durch nationale oder europäische Vorschriften,
          denen wir unterliegen, vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt in diesem Fall dann,
          wenn die in den jeweiligen Vorschriften vorgeschriebene Speicherfrist abgelaufen ist. Letzteres gilt dann
          nicht, wenn eine weitere Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung
          erforderlich ist.
        </p>
        <p>
          (6) Soweit wir für einzelne Funktionen unserer Webseite auf beauftragte Dienstleister zurückgreifen oder Ihre
          Daten für werbliche Zwecke nutzen möchten, werden wir Sie nachfolgend im Detail über die jeweiligen Vorgänge
          informieren.
        </p>
        <h2 id="verantwortliche-stelle" class="my-4">
          II. Verantwortliche Stelle
        </h2>
        <p>
          (1) Verantwortlicher im Sinne des Art. 4 Nr. 7 DSGVO, der sonstigen in den Mitgliedstaaten der Europäischen
          Union geltenden Datenschutzgesetze und anderer Vorschriften sowie Bestimmungen mit datenschutzrechtlichem
          Charakter ist:<br><br>AGFEO GmbH &amp; Co. KG<br>vertreten durch die AGFEO Beteiligungsgesellschaft mit
          beschränkter Haftung<br>diese wiederum vertreten durch Michael Boelke, Michael Born<br><br>Gaswerkstr.
          8<br>33647 Bielefeld<br><br>Tel.: +49 521 447090<br>Fax: +49 521 4470950<br>E-Mail: <a
            class="text-primary"
            href="mailto:youtube@agfeo.de"
          >youtube@agfeo.de</a><br><br>Registergericht: Amtsgericht
          Bielefeld<br>Registernummer: HRA 10171
        </p>
        <p>(2) Weitere Einzelheiten zur verantwortlichen Stelle können Sie unserem Impressum entnehmen.</p>
        <h2 id="datenschutzbeauftragter" class="my-4">
          III. Datenschutzbeauftragter
        </h2>
        <p>
          Unseren Datenschutzbeauftragten können Sie unter der nachfolgenden Adresse erreichen und kontaktieren:<br>
        </p>
        <p>
          Carsten Bruns<br>SK-Consulting Group GmbH<br>Osterweg 2<br>32549 Bad Oeynhausen<br>Tel: 05731
          49064-30<br>E-Mail: <a class="text-primary" href="mailto:datenschutz@sk-consulting.com">datenschutz@sk-consulting.com</a><br>
        </p>
        <h2 id="ihre-rechte" class="my-4">
          IV. Ihre Rechte
        </h2>
        <p>
          (1) Sie haben uns gegenüber hinsichtlich der Sie betreffenden personenbezogenen Daten die nachfolgenden
          Rechte:
        </p>
        <ul class="pl-6">
          <li>das Recht auf Auskunft,</li>
          <li>das Recht auf Berichtigung und Löschung,</li>
          <li>das Recht auf Einschränkung der Verarbeitung,</li>
          <li>das Recht auf Widerspruch gegen die Verarbeitung,</li>
          <li>das Recht auf Datenübertragbarkeit.</li>
        </ul>
        <p>
          (2) Sie haben zusätzlich das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer
          personenbezogenen Daten durch uns zu beschweren.
        </p>
        <p>(3) Im Geltungsbereich des DSG haben Sie zudem das Recht auf:</p>
        <ul class="pl-6">
          <li>Datenherausgabe,</li>
          <li>Datenvernichtung</li>
        </ul>
        <h2 id="verarbeitung-personenbezogener-daten-bei-informatorischer-nutzung-unserer-webseite" class="my-4">
          V. Verarbeitung personenbezogener Daten bei informatorischer Nutzung unserer Webseite
        </h2>
        <p>
          (1) Wenn Sie unsere Webseite aufrufen, ohne sich dabei zu registrieren oder uns auf andere Weise
          Informationen zukommen zu lassen ('Informatorische Nutzung'), erheben wir nur diejenigen personenbezogenen
          Daten, die Ihr Webbrowser an unseren Server übermittelt. Wenn Sie unsere Webseite betrachten möchten, erheben
          wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen die Anzeige unserer Webseite zu
          ermöglichen und die Stabilität und Sicherheit zu gewährleisten: IP-Adresse, Datum und Uhrzeit der Anfrage,
          Zeitzonendifferenz zu GMT, Inhalt der Webseite, Zugriffsstatus (HTTP-Status), übertragene Datenmenge,
          Anforderungs-Website, Webbrowser, Betriebssystem, Sprache und Version des Browsers
        </p>
        <p>
          (2) Die vorgenannten Daten werden ebenfalls in sog. Logfiles auf unseren Servern gespeichert. Eine
          Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten von Ihnen findet nicht statt.
        </p>
        <p>
          (3) Die Speicherung der oben genannten Daten in Logfiles dient der Sicherstellung der Funktionsfähigkeit und
          zur Optimierung unserer Webseite sowie zur Sicherstellung der Sicherheit unserer informationstechnischen
          Systeme.
        </p>
        <p>
          (4) Eine Auswertung dieser Daten zu Marketingzwecken findet nicht statt.In den vorstehenden Zwecken liegt
          unser berechtigtes Interesse an der Datenverarbeitung. Rechtsgrundlage für die Erhebung und die vorübergehende
          Speicherung der vorgenannten Daten und der Logfiles ist Art. 6 Abs. 1 S. 1 lit. f) DSGVO.Die vorstehenden
          Daten zur Bereitstellung unserer Website werden gelöscht, wenn die jeweilige Sitzung beendet ist. Die Erhebung
          der vorstehenden Daten zur Bereitstellung unserer Webseite ist für den Betrieb unserer Website zwingend
          erforderlich. Es besteht keine Widerspruchsmöglichkeit.
        </p>
        <h2 id="verarbeitung-personenbezogener-daten-durch-cookies" class="my-4">
          VI. Verarbeitung personenbezogener Daten durch Cookies
        </h2>
        <p>
          (1) Wir verwenden auf unserer Webseite sog. Cookies. Bei Cookies handelt es sich um kleine Textdateien, die
          auf dem Speichermedium Ihres Endgerätes, also beispielsweise auf einer Festplatte, gespeichert werden und
          durch die uns als Stelle, die den Cookie setzt, bestimmte Informationen zufließen. Cookies können keine
          Programme ausführen oder Viren auf Ihr Endgerät übertragen. Diese Webseite verwendet folgende Arten von
          Cookies, deren Umfang und Funktionsweise nachfolgend erläutert werden.
        </p>
        <p>(2) Cookies, die Ihrem Webbrowser zugehörig gespeichert werden:</p>
        <ul class="pl-6">
          <li>
            Transiente Cookies: Diese Cookies werden automatisiert gelöscht, wenn Sie Ihren Webbrowser schließen. Dazu
            zählen insbesondere Session-Cookies. Diese speichern eine sog. Session-ID, anhand der sich verschiedene
            Anfragen Ihres Webbrowsers der gemeinsamen Sitzung zuordnen lassen. Dies ermöglicht eine Wiedererkennung
            Ihres Endgeräts, wenn Sie auf unsere Website zurückkehren. Session-Cookies werden gelöscht, sobald Sie sich
            ausloggen oder den Webbrowser schließen.
          </li>
        </ul>
        <ul class="pl-6">
          <li>
            Persistente Cookies: Diese Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich
            je nach Cookie unterscheiden kann. Diese Cookies können Sie in den Einstellungen Ihres Webbrowsers jederzeit
            löschen.
          </li>
        </ul>
        <p>
          (3) Die Verarbeitung personenbezogener Daten durch die vorstehenden Cookies dient dazu, das Angebot unserer
          Webseite insgesamt für Sie nutzerfreundlicher und effektiver zu machen. Einige Funktionen unserer Webseite
          können ohne den Einsatz dieser Cookies nicht angeboten werden. Insbesondere erfordern einige Funktionen
          unserer Webseite es, dass Ihr Webbrowser auch noch nach einem Seitenwechsel identifiziert werden kann. Soweit
          Sie über einen Account verfügen, setzen wir die Cookies ein, um Sie für Folgebesuche identifizieren zu können.
          Dadurch wird vermieden, dass Sie sich bei jedem Besuch unserer Webseite erneut einloggen müssen. Diejenigen
          Daten, die durch Cookies verarbeitet werden, die für die Bereitstellung der Funktionen unserer Webseite
          erforderlich sind, werden nicht zur Erstellung von Nutzerprofilen verwendet. Soweit Cookies zu Analysezwecken
          eingesetzt werden, dienen diese dazu, die Qualität und Nutzerfreundlichkeit unserer Webseite, ihrer Inhalte
          und Funktionen zu verbessern. Sie ermöglichen uns, nachzuvollziehen, wie die Webseite, welche Funktionen und
          wie oft diese genutzt werden. Dies ermöglicht uns, unser Angebot fortlaufend zu optimieren.
        </p>
        <p>
          (4) Soweit Cookies nicht technisch zwingend erforderlich sind, setzen wir diese nur mit Ihrer zuvor erklärten
          Einwilligung, die Sie zudem jederzeit widerrufen können. Rechtsgrundlage ist Art. 6 Abs. 1 lit. a) DSGVO.
        </p>
        <p>
          (5) Die vorstehenden Cookies werden auf Ihrem Endgerät gespeichert und von diesem an unseren Server
          übermittelt. Sie können daher die Verarbeitung der Daten und Informationen durch Cookies selbst konfigurieren.
          Sie können in den Einstellungen Ihres Webbrowsers entsprechende Konfigurationen vornehmen, durch die Sie
          beispielsweise Third-Party-Cookies oder Cookies insgesamt ablehnen können. In diesem Zusammenhang möchten wir
          Sie darauf hinweisen, dass Sie dann eventuell nicht alle Funktionen unserer Webseite ordnungsgemäß nutzen
          können. Darüber hinaus empfehlen wir eine regelmäßige manuelle Löschung von Cookies sowie Ihres
          Browser-Verlaufs.
        </p>
        <h2 id="weitere-funktionen-und-angebote-unserer-webseite" class="my-4">
          VII. Weitere Funktionen und Angebote unserer Webseite
        </h2>
        <p>
          (1) Neben der vorbeschriebenen informatorischen Nutzung unserer Webseite bieten wir verschiedene Leistungen
          an, die Sie bei Interesse nutzen können. Hierfür ist in der Regel die Angabe weiterer personenbezogener Daten
          notwendig. Diese Daten benötigen wir zur Erbringung der jeweiligen Leistung. Es gelten hierfür die
          vorstehenden Grundsätze zur Datenverarbeitung.
        </p>
        <p>
          (2) Zum Teil greifen wir zur Verarbeitung dieser Daten auf externe Dienstleister zurück, die von uns
          sorgfältig ausgewählt und beauftragt wurden. Diese Dienstleister sind an unsere Weisungen gebunden und werden
          regelmäßig von uns kontrolliert. Soweit personenbezogene Daten im Zuge von Leistungen, die wir gemeinsam mit
          Partnern anbieten, an Dritte weitergegeben werden, können Sie nähere Informationen den nachfolgenden
          Beschreibungen der einzelnen Leistungen entnehmen. Soweit diese Dritten ihren Sitz in einem Staat außerhalb
          des Europäischen Wirtschaftsraumes haben, können Sie nähere Informationen über die Folgen dieses Umstands in
          den nachfolgenden Beschreibungen der einzelnen Leistungen entnehmen.
        </p>
        <h2 id="kontaktaufnahme" class="my-4">
          VIII. Kontaktaufnahme
        </h2>
        <p>
          (1) Wenn Sie mit uns per E-Mail Kontakt aufnehmen, werden die von Ihnen an uns mit Ihrer E-Mail übermittelten
          personenbezogenen Daten gespeichert.
        </p>
        <p>
          (2) Zudem halten wir auf unserer Webseite ein Kontaktformular vor, mit dem Sie Kontakt zu uns aufnehmen
          können. Dabei werden die von Ihnen in die Eingabemaske eingegebenen Daten an uns übermittelt und gespeichert:
          Vorname, Name, E-Mailadresse, Anschrift, Telefon, Unternehmen.
        </p>
        <p>
          (3) Die Daten werden ausschließlich zur Beantwortung Ihrer Fragen verwendet. Soweit dies nicht explizit in
          dieser Datenschutzerklärung angegeben ist, erfolgt keine Weitergabe der Daten an Dritte. Zusätzlich erfassen
          wir Ihre IP-Adresse und den Zeitpunkt der Absendung.
        </p>
        <p>
          (4) Die Verarbeitung der vorstehenden personenbezogenen Daten dient allein zur Bearbeitung Ihrer Anfragen.
        </p>
        <p>
          (5) Die Verarbeitung weiterer personenbezogener Daten, die durch die Verwendung des auf unserer Webseite
          vorgehaltenen Kontaktformulars anfallen, dienen zur Verhinderung des Missbrauchs sowie zur Sicherstellung der
          Sicherheit unserer informationstechnischen Systeme.
        </p>
        <p>
          (6) Hierin liegt auch unser berechtigtes Interesse an der Verarbeitung Ihrer personenbezogenen Daten. Soweit
          Sie uns hierfür eine Einwilligung erteilt haben, ist Rechtsgrundlage für die Verarbeitung dieser Daten Art. 6
          Abs. 1 lit. a) DSGVO. Im Übrigen ist Rechtsgrundlage für die Verarbeitung dieser Daten Art. 6 Abs. 1 lit. f)
          DSGVO, insbesondere für den Fall, dass uns die Daten von Ihnen durch Übersendung einer E-Mail übermittelt
          werden. Soweit Sie durch Ihre E-Mail auf den Abschluss eines Vertrages hinwirken wollen, stellt Art. 6 Abs. 1
          lit. b) DSGVO eine zusätzliche Rechtsgrundlage dar.
        </p>
        <p>
          (7) Die Daten werden vorbehaltlich gesetzlicher Aufbewahrungsfristen gelöscht, sobald wir Ihre Anfrage
          abschließend bearbeitet haben. Bei einer Kontaktaufnahme per E-Mail können Sie der Speicherung Ihrer
          personenbezogenen Daten jederzeit widersprechen. Wir weisen Sie darauf hin, dass in diesem Fall Ihre Anfrage
          nicht weiterbearbeitet werden kann. Den Widerruf oder den Widerspruch können Sie durch Übersendung einer
          E-Mail an unsere im Impressum angegebene E-Mail-Adresse erklären.
        </p>
        <div id="YouTube">
          <h2 class="my-4">
            IX. YouTube
          </h2>
          <p>Wir setzen den Dienst auf unserer Website ein.</p>
          <p>
            Beim Aufruf einer Webseite, in die ein Medieninhalt des Anbieters eingebunden ist, werden Daten an einen
            Server des Anbieters übertragen und dort gespeichert. Wenn Sie über ein Nutzerkonto bei dem Anbieter
            verfügen und registriert sind, kann der Anbieter dadurch den Besuch Ihrem Nutzerkonto zuordnen. Der Anbieter
            speichert diese Daten als Nutzungsprofile und nutzt diese für Zwecke der Werbung, Marktforschung und/oder
            bedarfsgerechten Gestaltung seiner Webseiten. Eine solche Auswertung erfolgt insbesondere (auch für nicht
            eingeloggte Nutzer) zur Darstellung von bedarfsgerechter Werbung und um andere Nutzer über Ihre Aktivitäten
            auf unserem Internetauftritt zu informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser
            Nutzerprofile. Bitte wenden Sie sich hierzu direkt an Anbieter.
          </p>
          <p>
            Wir binden Inhalte des Anbieters auf den Webseiten unserer Internetpräsenz ein, um Ihnen diese Inhalte
            unmittelbar verfügbar zu machen, ohne dass Sie die Inhalte gesondert auf Seiten des Anbieters abrufen
            müssen. Dies erlaubt uns, unser Angebot sowie das Nutzungserlebnis für Sie zu verbessern und interessanter
            auszugestalten.
          </p>
          <p>
            Der Dienst übermittelt personenbezogene Daten in die USA. Die EU-Kommission hat beschlossen, dass dieses
            Land ein angemessenes Datenschutzniveau bietet (TADPF). Der Dienst hat sich dem TADPF unterworfen.
          </p>
          <p>Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. a) DSGVO (Einwilligung).</p>
          <p>
            Anbieter:<br>Google Ireland Limited<br>Google Building Gordon House<br>Barrow St<br>4
            Dublin<br>Irland<br>Tel. +353 1 543 1000<br>Fax +353 1 686 5660<br>https://www.google.de/
          </p>
          <p>https://policies.google.com/privacy?hl=de&amp;gl=de</p>
        </div><input
          type="hidden"
          name="avalex-legaltext"
          value="avalex-datenschutzerklaerung-de-0ofaimMPFZ"
          autocomplete="off"
        >
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'data-protection-view',
}
</script>
