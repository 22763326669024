<template>
  <div>
    <p class="mb-4">
      AGFEO ist Kommunikationsanbieter mit langer Tradition. Seit 1947 haben wir, wiederkehrend und unabhängig durch den Markt als Gewinner zahlreicher Wettbewerbe und Marktumfragen bestätigt, entsprechende Expertise über „gute Telefonie“ aufgebaut. Anders als manch andere CloudPBX Lösungen kennen wir seit vielen Jahren die Belange unserer Kunden – und haben unsere Produkte entsprechend entwickelt und aufeinander bestmöglich abgestimmt.
    </p>

    <p class="mb-4">
      Dabei steht die Sicherheit für AGFEO steht’s im Fokus. Wir entwickeln stets Security by Design! Denn Ihre Daten sind unser höchstes Gut – und Ihre Kommunikation braucht einen sicheren Partner.
    </p>

    <p class="mb-4">
      Die HyperFonie folgt dieser Tradition und unser Leitspruch gilt selbstverständlich auch für die Cloud:
    </p>

    <p>
      AGFEO <br>
      einfach | perfekt | kommunizieren
    </p>
  </div>
</template>

<script>
export default {
  name: 'communication-specialist',
}
</script>
