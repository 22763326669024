<template>
  <div>
    <p class="mb-4">
      Das AGFEO Dashboard integriert als Cockpit-Software nicht nur auf höchst komfortable und anpassbare Weise zahlreiche Funktionen der HyperFonie.
    </p>

    <p class="mb-4">
      Es bietet dem Anwender umfassendste Funktionen und dient u.a. der Visualisierung inkl. Steuer- und Bedieneinheit für Anruflisten, Teams/Gruppen, Wartefeld, VoiceBox, FaxBox, Präsenz, Chat, Kalenderfunktionen und zahlreicher anderer Systemmerkmale unserer HyperFonie.
    </p>

    <p class="mb-4">
      Die Software integriert Kontaktdaten zahlreicher anderer Datenquellen (CRM/ERP, Outlook, Google, LDAP, ODBC etc.) und ist auch in der Lage Rufinformationen an fremde Software zu übergeben oder von dieser gesteuert zu werden.
    </p>

    <p class="mb-4">
      Nicht nur Anwender ohne physikalisches Endgerät profitieren dabei durch die integrierte SoftPhone Funktion und Team-Meetings könnt Ihr fortan über Videokonferenzen abhalten. Mit Euren eigenen Mitarbeitern und selbstverständlich auch mit externen Partnern. <br>
      Dabei ist diese PC-Software übrigens nicht nur für MS Windows, sondern auch für Apple MacOS und optional sogar für Linux verfügbar und das auch ohne Funktionsunterschiede!
    </p>
  </div>
</template>

<script>
export default {
  name: 'multi-device',
}
</script>
