import Api from './api'

export default {
  baseUrl: 'Config',

  /**
   * getUiConfig
   *
   * @returns {Promise}
   */
  getUiConfig () {
    return fetch(`${Api.baseUrl}/${this.baseUrl}/Ui`, {
      headers: {
        ...Api.baseHeader,
      }
    })
  },
}
