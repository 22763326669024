import { defineStore } from 'pinia'

export const useSnackbarStore = defineStore('snackbar', {
  state: () => ({
    color: 'success',
    elevation: 24,
    location: 'bottom center',
    text: '',
    timeout: 4000,
    visible: false,
  }),
})
