<template>
  <div class="learn-more--wrap">
    <v-sheet
      class="pa-8 pa-sm-15 rounded-xl d-flex align-center flex-wrap text-center text-md-left"
    >
      <v-row>
        <v-col
          :cols="12"
          :lg="9"
        >
          <hyper-heading
            title="Möchten Sie mehr erfahren?"
            subtitle="Kompetente Hilfe vor Ort"
            class="mb-8 mb-sm-15"
          />

          <p class="mb-15 text-h5">
            Deutschlandweit werden wir von über 1200 hilfsbereiten Fachhändlern unterstützt.
            Finden Sie Ihren Ansprechpartner direkt in Ihrer Nähe, welcher für Sie die perfekte Lösung finden wird.
          </p>

          <v-btn
            size="large"
            append-icon="search"
            color="primary"
            flat
            class="font-weight-bold"
            @click="showDialog = true"
          >
            Fachhändler-Suche
          </v-btn>
        </v-col>
        <v-col
          :cols="12"
          :lg="3"
          class="d-flex"
        >
          <div class="mx-auto pa-4 d-flex align-center justify-center">
            <undraw-certificate :fill="$vuetify.theme.themes.light.colors.primary" />
          </div>
        </v-col>
      </v-row>
    </v-sheet>

    <support-search-dialog
      :allow-choice="false"
      :is-visible="showDialog"
      @cancel="showDialog = false"
    />
  </div>
</template>

<script>
import HyperHeading from '@/components/HyperHeading.vue'
import UndrawCertificate from './icons/UndrawCertificate.vue'
import SupportSearchDialog from '@/components/SupportSearchDialog.vue'

export default {
  name: 'learn-more',

  components: {
    HyperHeading,
    UndrawCertificate,
    SupportSearchDialog,
  },

  data () {
    return {
      showDialog: false,
    }
  },
}
</script>

<style lang="scss">
  .learn-more--wrap {
    position: relative;

    .undraw-certificate--wrap {
      min-width: 240px;
    }

    .text-wrap {
      flex: 1;
    }
  }
</style>
