import { createApp } from 'vue'
import { createPinia } from 'pinia'

import 'material-icons/iconfont/filled.css'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import App from './App.vue'
import router from './router'
import filters from './filters'

import vuetifyPlugin from '@/plugins/vuetify'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = createApp(App)
app.use(pinia)
app.use(router)
app.use(vuetifyPlugin)

app.config.globalProperties.$filters = filters

app.mount('#app')
