<template>
  <div class="imprint-view--wrap main-bg-gradient flex-grow-1">
    <v-container class="py-8 py-sm-15">
      <h1 class="mb-8">
        Impressum
      </h1>

      <h3 class="mb-4">
        AGFEO GmbH & Co.KG
      </h3>
      <p class="mb-8">
        Gaswerkstraße 8 <br>
        33647 Bielefeld <br>
        Telefon: +49 521 44709-0 <br>
        Telefax: +49 521 44709-50 <br>
        E-Mail: <a class="text-primary" href="mailto:info@agfeo.de">info@agfeo.de</a> <br>
      </p>

      <h3 class="mb-4">
        Vertreten durch:
      </h3>
      <p class="mb-8">
        AGFEO Beteiligungsges. mbH <br>
        Geschäftsführer: Michael Boelke, Michael Born <br>
        Registergericht: Amtsgericht Bielefeld <br>
        Registernummer: HRA 10171 <br>
      </p>

      <h3 class="mb-4">
        Umsatzsteuer-ID:
      </h3>
      <p class="mb-8">
        Umsatzsteuer-Identifikationsnummer nach §27a Umsatzsteuergesetz: <br>
        DE126937545
      </p>

      <h3 class="mb-4">
        Verantwortlich für den Inhalt (gem. § 55 Abs. 2 RStV):
      </h3>
      <p class="mb-8">
        Niko Timm<br>
        <a class="text-primary" href="mailto:ntimm@agfeo.de">ntimm@agfeo.de</a>
      </p>

      <h3 class="mb-4">
        Über Anregungen zu unseren Internetseiten freuen wir uns. <br>
        Ihr AGFEO Redaktionsteam:
      </h3>
      <p class="mb-8">
        Lars Husemann<br>
        <a class="text-primary" href="mailto:lhusemann@agfeo.de">lhusemann@agfeo.de</a>
      </p>

      <h3 class="mb-4">
        Funktioniert auf den Seiten irgend etwas nicht?
      </h3>
      <p class="mb-8">
        Webmaster <br>
        <a class="text-primary" href="mailto:webmaster@agfeo.de">webmaster@agfeo.de</a>
      </p>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'imprint-view',
}
</script>
