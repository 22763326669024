<template>
  <div class="additional-options-choice--wrap">
    <div
      v-for="(option, i) in additionalOptions"
      :key="i"
      class="my-2"
    >
      <v-checkbox
        :id="option.value"
        :model-value="modelValue"
        :value="option"
        hide-details
        @update:model-value="choice => $emit('update:model-value', choice)"
      >
        <template #label>
          <div>
            <div class="text-h5 font-exo">
              {{ option.title }}
            </div>
            <div class="font-exo">
              {{ option.price }},- € / Monat
            </div>
          </div>
        </template>
      </v-checkbox>
    </div>
  </div>
</template>

<script>
import additionalOptions from '@/fakedata/instanceOptions.js'

export default {
  name: 'additional-options-choice',

  props: {
    modelValue: {
      type: Array,
      default: () => ([])
    }
  },

  emits: [
    'update:model-value',
  ],

  data () {
    return {
      additionalOptions,
    }
  },
}
</script>

<style lang="scss">
  .additional-options-choice--wrap {
    .v-checkbox {
      .v-checkbox-btn {
        align-items: flex-start;

        .v-selection-control__wrapper {
          top: -4px;
        }
      }

      .v-icon {
        --v-icon-size-multiplier: 1.25;
      }
    }
  }
</style>
