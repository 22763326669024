<template>
  <div class="home-view--wrap">
    <main-hero />

    <v-container class="py-8 py-sm-15">
      <launch-stream />
      <claims-view />

      <v-sheet class="pa-8 pa-sm-15 mb-15" color="white" rounded="xl">
        <plans-grid />
      </v-sheet>
      <learn-more />
    </v-container>
  </div>
</template>

<script>
import ClaimsView from '@/components/ClaimsView.vue'
import LaunchStream from '@/components/LaunchStream.vue'
import LearnMore from '@/components/LearnMore.vue'
import MainHero from '@/components/MainHero.vue'
import PlansGrid from '@/components/PlansGrid.vue'

export default {
  name: 'home-view',

  components: {
    ClaimsView,
    LaunchStream,
    LearnMore,
    MainHero,
    PlansGrid,
  },
}
</script>
