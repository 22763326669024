<template>
  <div>
    <p>
      Angepasste Funktionen nicht nur mit Software und Apps, sondern eben auch schnurgebundenen und schnurlosen Endgeräten, die so nur ein Systemanbieter unterstützt! AGFEO bietet höchst komfortable schnurgebundene und schnurlose DECT IP Systemgeräte und darüber hinaus eigene HeadSets, Türstationen und mehr. Und da voll integriert, immer mit deutlich<br>
      MEHR Funktionen <br>
      MEHR Komfort <br>
      EINFACHERER Bedienung.
    </p>
  </div>
</template>

<script>
export default {
  name: 'software-apps',
}
</script>
