<template>
  <div class="agfeo-logo--wrap">
    <svg
      id="Ebene_1"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 294 94"
      style="enable-background:new 0 0 294 94;"
      xml:space="preserve"
    >
      <g>
        <g>
          <g>
            <path
              :fill="fill"
              d="M4.1,81.5c0,1.2,0.3,2.1,0.8,2.8c0.5,0.6,1.3,1,2.3,1c0.6,0,1-0.1,1.4-0.3c0.4-0.2,0.8-0.5,1.2-0.9l0.7,0.6
            c-0.2,0.2-0.5,0.4-0.7,0.6S9.3,85.7,9,85.8c-0.3,0.1-0.6,0.2-0.9,0.3s-0.7,0.1-1.1,0.1c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1-2-1-3.6
            c0-1.5,0.3-2.7,1-3.6c0.7-0.9,1.6-1.3,2.8-1.3c1.2,0,2.1,0.4,2.8,1.2c0.7,0.8,1,1.9,1,3.4v0.5H4.1z M9.6,80.2
            c0-0.2,0-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.4c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.5-0.5-0.9-1-1.2s-0.9-0.4-1.5-0.4s-1,0.1-1.5,0.4
            c-0.4,0.3-0.8,0.7-1,1.2c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.3-0.1,0.4c0,0.2,0,0.4,0,0.6h5.6
            C9.7,80.5,9.6,80.3,9.6,80.2z"
            />
            <path :fill="fill" d="M13.8,73.3v-1.2H15v1.2H13.8z M13.9,86.1v-9.5h1v9.5H13.9z" />
            <path
              :fill="fill"
              d="M25,86.1v-5.9c0-0.9-0.2-1.6-0.7-2.1s-1.1-0.7-1.9-0.7c-0.9,0-1.5,0.2-2,0.7c-0.5,0.5-0.7,1.2-0.7,2.1v5.9
            h-1v-9.5h1v1.1c0.7-0.8,1.6-1.2,2.8-1.2c1,0,1.9,0.3,2.5,0.9c0.7,0.6,1,1.5,1,2.6v6H25z"
            />
            <path
              :fill="fill"
              d="M31,77.3v8.7h-1v-8.7h-1.3v-0.7H30v-2c0-0.7,0.2-1.3,0.6-1.8c0.4-0.5,1-0.7,1.7-0.7h0.9V73h-0.7
            c-0.5,0-0.9,0.1-1.1,0.4C31.1,73.6,31,74,31,74.5v2.1h2.1v0.7H31z"
            />
            <path
              :fill="fill"
              d="M40.8,86.1v-0.9c-0.4,0.4-0.8,0.7-1.2,0.8s-1,0.2-1.6,0.2c-0.7,0-1.3-0.1-1.7-0.2c-0.4-0.1-0.8-0.4-1.1-0.7
            c-0.2-0.2-0.4-0.5-0.5-0.8c-0.1-0.3-0.2-0.7-0.2-1c0-0.9,0.3-1.5,0.9-2c0.6-0.5,1.4-0.7,2.4-0.7h3.1v-1.1c0-0.8-0.2-1.3-0.6-1.7
            c-0.4-0.4-1.1-0.6-2.1-0.6c-0.7,0-1.2,0.1-1.6,0.3c-0.4,0.2-0.7,0.5-1,0.9l-0.7-0.6c0.4-0.6,0.9-0.9,1.4-1.1
            c0.5-0.2,1.2-0.3,1.9-0.3c1.3,0,2.2,0.3,2.8,0.8c0.6,0.5,0.9,1.3,0.9,2.3v6.5H40.8z M40.8,81.5h-2.9c-1.6,0-2.4,0.6-2.4,1.9
            c0,0.6,0.2,1.1,0.6,1.4c0.4,0.3,1,0.5,1.9,0.5c0.4,0,0.8,0,1.2-0.1c0.4-0.1,0.8-0.3,1.1-0.6c0.4-0.3,0.5-0.9,0.5-1.8V81.5z"
            />
            <path
              :fill="fill"
              d="M51.6,85.4c-0.2,0.2-0.5,0.3-0.7,0.5s-0.5,0.2-0.8,0.2s-0.6,0.1-0.9,0.1c-0.6,0-1.2-0.1-1.7-0.3
            c-0.5-0.2-1-0.5-1.3-1c-0.4-0.4-0.6-0.9-0.8-1.5c-0.2-0.6-0.3-1.3-0.3-2c0-0.7,0.1-1.4,0.3-2c0.2-0.6,0.5-1.1,0.8-1.5
            c0.4-0.4,0.8-0.7,1.3-1c0.5-0.2,1.1-0.3,1.7-0.3c0.3,0,0.6,0,0.9,0.1s0.5,0.1,0.8,0.2c0.2,0.1,0.5,0.3,0.7,0.5
            c0.2,0.2,0.5,0.4,0.7,0.7l-0.7,0.6c-0.4-0.4-0.8-0.8-1.1-0.9c-0.4-0.2-0.8-0.3-1.3-0.3c-0.5,0-1,0.1-1.4,0.3
            c-0.4,0.2-0.8,0.5-1.1,0.9c-0.2,0.3-0.4,0.7-0.5,1.1c-0.1,0.4-0.1,0.9-0.1,1.6s0,1.2,0.1,1.6c0.1,0.4,0.3,0.8,0.5,1.1
            c0.3,0.4,0.6,0.7,1.1,0.9c0.4,0.2,0.9,0.3,1.4,0.3c0.2,0,0.5,0,0.7-0.1s0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.2,0.6-0.4
            c0.2-0.2,0.4-0.3,0.6-0.6l0.7,0.6C52,85,51.8,85.2,51.6,85.4z"
            />
            <path
              :fill="fill"
              d="M61.5,86.1v-5.9c0-0.9-0.2-1.6-0.7-2.1c-0.4-0.5-1.1-0.7-1.9-0.7s-1.5,0.2-2,0.7c-0.5,0.5-0.7,1.2-0.7,2.1
            v5.9h-1v-14h1v5.6c0.7-0.8,1.6-1.2,2.8-1.2c1.1,0,1.9,0.3,2.6,0.9c0.6,0.6,0.9,1.5,0.9,2.6v6H61.5z"
            />
            <path :fill="fill" d="M71.9,87.5V70.6h1v16.9H71.9z" />
            <path
              :fill="fill"
              d="M89.6,83.5c-0.2,0.7-0.5,1.3-1,1.8c-0.2,0.2-0.6,0.4-1,0.6s-0.8,0.2-1.3,0.2c-0.5,0-1-0.1-1.5-0.3
            s-0.9-0.5-1.3-1.1v5.7h-1v-14h1v1.2c0.4-0.5,0.9-0.9,1.3-1.1s1-0.3,1.5-0.3c0.5,0,0.9,0.1,1.3,0.2s0.7,0.4,1,0.6
            c0.5,0.5,0.8,1.1,1,1.8c0.2,0.7,0.2,1.5,0.2,2.2C89.8,82.1,89.7,82.8,89.6,83.5z M88.7,79.9c-0.1-0.5-0.2-0.9-0.4-1.3
            c-0.2-0.4-0.5-0.7-0.8-0.9c-0.4-0.2-0.8-0.3-1.4-0.3c-0.6,0-1,0.1-1.4,0.3c-0.4,0.2-0.6,0.5-0.8,0.9c-0.2,0.4-0.3,0.8-0.4,1.3
            c-0.1,0.5-0.1,1-0.1,1.4c0,0.5,0,1,0.1,1.5c0.1,0.5,0.2,0.9,0.4,1.3c0.2,0.4,0.5,0.7,0.8,0.9c0.4,0.2,0.8,0.3,1.4,0.3
            c0.6,0,1-0.1,1.4-0.3c0.4-0.2,0.6-0.5,0.8-0.9c0.2-0.4,0.3-0.8,0.4-1.3c0.1-0.5,0.1-1,0.1-1.5C88.8,80.8,88.8,80.4,88.7,79.9z"
            />
            <path
              :fill="fill"
              d="M93.5,81.5c0,1.2,0.3,2.1,0.8,2.8c0.5,0.6,1.3,1,2.3,1c0.6,0,1-0.1,1.4-0.3c0.4-0.2,0.8-0.5,1.2-0.9l0.7,0.6
            c-0.2,0.2-0.5,0.4-0.7,0.6s-0.5,0.3-0.8,0.5c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.7,0.1-1.1,0.1c-1.3,0-2.3-0.4-3-1.2
            c-0.7-0.8-1-2-1-3.6c0-1.5,0.3-2.7,1-3.6c0.7-0.9,1.6-1.3,2.8-1.3s2.1,0.4,2.8,1.2c0.7,0.8,1,1.9,1,3.4v0.5H93.5z M99.1,80.2
            c0-0.2,0-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.4c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.5-0.5-0.9-1-1.2s-0.9-0.4-1.5-0.4s-1,0.1-1.5,0.4
            s-0.8,0.7-1,1.2c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.3-0.1,0.4c0,0.2,0,0.4,0,0.6h5.6
            C99.1,80.5,99.1,80.3,99.1,80.2z"
            />
            <path
              :fill="fill"
              d="M108.7,78.1c-0.3-0.3-0.6-0.5-0.8-0.6c-0.3-0.1-0.6-0.2-1-0.2c-0.4,0-0.8,0.1-1.1,0.2
            c-0.3,0.2-0.6,0.4-0.8,0.6c-0.2,0.3-0.4,0.6-0.5,0.9c-0.1,0.3-0.2,0.7-0.2,1.1v5.8h-1v-9.5h1v1.3c0.3-0.4,0.7-0.8,1.2-1
            c0.5-0.2,1-0.4,1.6-0.4c0.5,0,0.9,0.1,1.2,0.2c0.4,0.1,0.7,0.4,1.1,0.7L108.7,78.1z"
            />
            <path
              :fill="fill"
              d="M112.7,77.3v8.7h-1v-8.7h-1.3v-0.7h1.3v-2c0-0.7,0.2-1.3,0.6-1.8s1-0.7,1.7-0.7h0.9V73h-0.7
            c-0.5,0-0.9,0.1-1.1,0.4c-0.2,0.3-0.4,0.7-0.4,1.2v2.1h2.1v0.7H112.7z"
            />
            <path
              :fill="fill"
              d="M117.4,81.5c0,1.2,0.3,2.1,0.8,2.8c0.5,0.6,1.3,1,2.3,1c0.6,0,1-0.1,1.4-0.3c0.4-0.2,0.8-0.5,1.2-0.9
            l0.7,0.6c-0.2,0.2-0.5,0.4-0.7,0.6s-0.5,0.3-0.8,0.5c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.7,0.1-1.1,0.1c-1.3,0-2.3-0.4-3-1.2
            c-0.7-0.8-1-2-1-3.6c0-1.5,0.3-2.7,1-3.6c0.7-0.9,1.6-1.3,2.8-1.3s2.1,0.4,2.8,1.2c0.7,0.8,1,1.9,1,3.4v0.5H117.4z M122.9,80.2
            c0-0.2,0-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.4c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.5-0.5-0.9-1-1.2s-0.9-0.4-1.5-0.4s-1,0.1-1.5,0.4
            s-0.8,0.7-1,1.2c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.3-0.1,0.4c0,0.2,0,0.4,0,0.6h5.6
            C123,80.5,122.9,80.3,122.9,80.2z"
            />
            <path :fill="fill" d="M133.6,86.1l-3.3-5.2l-2.2,2.5v2.7h-1v-14h1v9.9l4.7-5.3h1.3l-3.1,3.5l3.8,5.9H133.6z" />
            <path
              :fill="fill"
              d="M140.1,86.1c-0.7,0-1.3-0.2-1.7-0.7c-0.4-0.5-0.6-1.1-0.6-1.8v-6.2h-1.3v-0.7h1.3v-3h1v3h2.1v0.7h-2.1v6.3
            c0,0.5,0.1,0.9,0.4,1.2c0.2,0.3,0.6,0.4,1.1,0.4h0.7v0.9H140.1z"
            />
            <path :fill="fill" d="M149.7,87.5V70.6h1v16.9H149.7z" />
            <path :fill="fill" d="M166.6,86.1l-3.3-5.2l-2.2,2.5v2.7h-1v-14h1v9.9l4.7-5.3h1.3l-3.1,3.5l3.8,5.9H166.6z" />
            <path
              :fill="fill"
              d="M176.9,83.4c-0.1,0.7-0.5,1.3-1,1.8c-0.3,0.3-0.7,0.6-1.2,0.8s-0.9,0.3-1.5,0.3c-1.1,0-1.9-0.3-2.6-1
            c-0.5-0.5-0.8-1.1-1-1.8c-0.1-0.7-0.2-1.4-0.2-2s0.1-1.4,0.2-2c0.1-0.7,0.5-1.3,1-1.8c0.7-0.7,1.6-1,2.6-1c0.5,0,1,0.1,1.5,0.3
            s0.8,0.4,1.2,0.8c0.5,0.5,0.8,1.1,1,1.8c0.1,0.7,0.2,1.4,0.2,2S177,82.7,176.9,83.4z M176.1,80.5c0-0.3,0-0.6-0.1-0.9
            c-0.1-0.3-0.1-0.5-0.3-0.8s-0.3-0.5-0.5-0.7c-0.3-0.3-0.6-0.5-0.9-0.6c-0.3-0.1-0.7-0.2-1.1-0.2c-0.8,0-1.4,0.3-2,0.8
            c-0.2,0.2-0.4,0.4-0.5,0.7s-0.2,0.5-0.3,0.8c-0.1,0.3-0.1,0.6-0.1,0.9c0,0.3,0,0.6,0,0.8c0,0.3,0,0.6,0,0.8c0,0.3,0,0.6,0.1,0.9
            s0.1,0.5,0.3,0.8c0.1,0.2,0.3,0.5,0.5,0.7c0.5,0.5,1.2,0.8,2,0.8c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.6-0.3,0.9-0.6
            c0.2-0.2,0.3-0.4,0.5-0.7c0.1-0.2,0.2-0.5,0.3-0.8c0.1-0.3,0.1-0.6,0.1-0.9c0-0.3,0-0.6,0-0.8C176.1,81,176.1,80.8,176.1,80.5z"
            />
            <path
              :fill="fill"
              d="M192.9,86.1v-5.9c0-0.9-0.2-1.6-0.7-2.1c-0.4-0.5-1.1-0.7-1.9-0.7c-0.8,0-1.5,0.2-2,0.7
            c-0.5,0.5-0.7,1.1-0.7,1.9v6h-1v-5.9c0-0.9-0.2-1.6-0.7-2.1c-0.4-0.5-1.1-0.7-1.9-0.7s-1.5,0.2-2,0.7c-0.5,0.5-0.7,1.2-0.7,2.1
            v5.9h-1v-9.5h1v1.1c0.7-0.8,1.6-1.2,2.8-1.2c0.7,0,1.3,0.1,1.8,0.4c0.5,0.3,0.9,0.7,1.2,1.3c0.4-0.6,0.8-1,1.3-1.3
            c0.5-0.3,1.2-0.4,1.9-0.4c0.5,0,1,0.1,1.4,0.2s0.8,0.4,1.1,0.7c0.7,0.6,1,1.5,1,2.6v6H192.9z"
            />
            <path
              :fill="fill"
              d="M210.2,86.1v-5.9c0-0.9-0.2-1.6-0.7-2.1s-1.1-0.7-1.9-0.7c-0.8,0-1.5,0.2-2,0.7c-0.5,0.5-0.7,1.1-0.7,1.9v6
            h-1v-5.9c0-0.9-0.2-1.6-0.7-2.1s-1.1-0.7-1.9-0.7c-0.9,0-1.5,0.2-2,0.7c-0.5,0.5-0.7,1.2-0.7,2.1v5.9h-1v-9.5h1v1.1
            c0.7-0.8,1.6-1.2,2.8-1.2c0.7,0,1.3,0.1,1.8,0.4c0.5,0.3,0.9,0.7,1.2,1.3c0.4-0.6,0.8-1,1.3-1.3c0.5-0.3,1.2-0.4,1.9-0.4
            c0.5,0,1,0.1,1.4,0.2c0.4,0.2,0.8,0.4,1.1,0.7c0.7,0.6,1,1.5,1,2.6v6H210.2z"
            />
            <path
              :fill="fill"
              d="M221,86.1v-1.1c-0.7,0.8-1.6,1.2-2.8,1.2c-1,0-1.9-0.3-2.5-0.9c-0.7-0.6-1-1.5-1-2.6v-6h1v5.9
            c0,0.9,0.2,1.6,0.7,2.1c0.4,0.5,1.1,0.7,1.9,0.7c0.9,0,1.5-0.2,2-0.7c0.5-0.5,0.7-1.2,0.7-2.1v-5.9h1v9.5H221z"
            />
            <path
              :fill="fill"
              d="M232.1,86.1v-5.9c0-0.9-0.2-1.6-0.7-2.1s-1.1-0.7-1.9-0.7c-0.9,0-1.5,0.2-2,0.7c-0.5,0.5-0.7,1.2-0.7,2.1
            v5.9h-1v-9.5h1v1.1c0.7-0.8,1.6-1.2,2.8-1.2c1,0,1.9,0.3,2.5,0.9c0.7,0.6,1,1.5,1,2.6v6H232.1z"
            />
            <path :fill="fill" d="M236.7,73.3v-1.2h1.2v1.2H236.7z M236.8,86.1v-9.5h1v9.5H236.8z" />
            <path :fill="fill" d="M240.8,86.1v-0.9l5.7-7.6h-5.4v-0.9h6.4v0.9l-5.7,7.6h5.7v0.9H240.8z" />
            <path :fill="fill" d="M250.4,73.3v-1.2h1.2v1.2H250.4z M250.5,86.1v-9.5h1v9.5H250.5z" />
            <path
              :fill="fill"
              d="M255.8,81.5c0,1.2,0.3,2.1,0.8,2.8c0.5,0.6,1.3,1,2.3,1c0.6,0,1-0.1,1.4-0.3c0.4-0.2,0.8-0.5,1.2-0.9
            l0.7,0.6c-0.2,0.2-0.5,0.4-0.7,0.6s-0.5,0.3-0.8,0.5c-0.3,0.1-0.6,0.2-0.9,0.3s-0.7,0.1-1.1,0.1c-1.3,0-2.3-0.4-3-1.2
            c-0.7-0.8-1-2-1-3.6c0-1.5,0.3-2.7,1-3.6c0.7-0.9,1.6-1.3,2.8-1.3c1.2,0,2.1,0.4,2.8,1.2c0.7,0.8,1,1.9,1,3.4v0.5H255.8z
            M261.3,80.2c0-0.2,0-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.4c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.5-0.5-0.9-1-1.2s-0.9-0.4-1.5-0.4
            s-1,0.1-1.5,0.4c-0.4,0.3-0.8,0.7-1,1.2c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.3-0.1,0.4c0,0.2,0,0.4,0,0.6
            h5.6C261.3,80.5,261.3,80.3,261.3,80.2z"
            />
            <path
              :fill="fill"
              d="M270.9,78.1c-0.3-0.3-0.6-0.5-0.8-0.6c-0.3-0.1-0.6-0.2-1-0.2c-0.4,0-0.8,0.1-1.1,0.2s-0.6,0.4-0.8,0.6
            c-0.2,0.3-0.4,0.6-0.5,0.9c-0.1,0.3-0.2,0.7-0.2,1.1v5.8h-1v-9.5h1v1.3c0.3-0.4,0.7-0.8,1.2-1c0.5-0.2,1-0.4,1.6-0.4
            c0.5,0,0.9,0.1,1.2,0.2s0.7,0.4,1.1,0.7L270.9,78.1z"
            />
            <path
              :fill="fill"
              d="M273.4,81.5c0,1.2,0.3,2.1,0.8,2.8c0.5,0.6,1.3,1,2.3,1c0.6,0,1-0.1,1.4-0.3c0.4-0.2,0.8-0.5,1.2-0.9
            l0.7,0.6c-0.2,0.2-0.5,0.4-0.7,0.6s-0.5,0.3-0.8,0.5c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.7,0.1-1.1,0.1c-1.3,0-2.3-0.4-3-1.2
            c-0.7-0.8-1-2-1-3.6c0-1.5,0.3-2.7,1-3.6c0.7-0.9,1.6-1.3,2.8-1.3s2.1,0.4,2.8,1.2c0.7,0.8,1,1.9,1,3.4v0.5H273.4z M278.9,80.2
            c0-0.2,0-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.4c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.5-0.5-0.9-1-1.2s-0.9-0.4-1.5-0.4s-1,0.1-1.5,0.4
            s-0.8,0.7-1,1.2c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.3-0.1,0.4c0,0.2,0,0.4,0,0.6h5.6
            C278.9,80.5,278.9,80.3,278.9,80.2z"
            />
            <path
              :fill="fill"
              d="M289.5,86.1v-5.9c0-0.9-0.2-1.6-0.7-2.1s-1.1-0.7-1.9-0.7c-0.9,0-1.5,0.2-2,0.7c-0.5,0.5-0.7,1.2-0.7,2.1
            v5.9h-1v-9.5h1v1.1c0.7-0.8,1.6-1.2,2.8-1.2c1,0,1.9,0.3,2.5,0.9c0.7,0.6,1,1.5,1,2.6v6H289.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              :fill="fill"
              d="M111,15.5c-2.9,2.1-5.5,4.1-8.1,6c-11.3-8.6-18.8-9.7-26.6-4c-7,5.2-9.6,13.6-6.6,21.7
            c3,8.2,10.6,12.9,19.6,12.2c7.7-0.6,13.8-5.9,15.5-13.9c-5.8,0-11.4,0-17.5,0c0-3.7,0-6.8,0-10.3c9.8,0,19.5,0,29.2,0
            c3,15.3-5.4,29.3-19.8,33.6c-15.6,4.6-31.8-2.5-37.6-16.6c-5.8-14.1,0-30.5,13.4-37.4C85.9-0.1,103.2,3.6,111,15.5z"
            />
          </g>
          <g>
            <g>
              <path
                :fill="fill"
                d="M260,3.4c16.8,0,30.3,13.1,30.3,29.4c0,16.4-13.8,29.5-30.8,29.4c-17-0.1-29.9-13-29.8-29.7
              C229.8,15.9,242.9,3.3,260,3.4z M259.9,51.5c10.1,0,18.7-8.4,18.7-18.5c0.1-10.2-8.6-19-18.8-19c-10.2,0-18.4,8.2-18.5,18.5
              C241.2,43.1,249.4,51.4,259.9,51.5z"
              />
            </g>
          </g>
          <g>
            <path
              :fill="fill"
              d="M193,37v13.4h31.8v10.3H182V37c0,0-6.5,0-6.5,0V26.6c-0.4,0.1,6.3,0,6.4,0V4.8h42.9V15h-31.8v11.6h22V37H193
            z"
            />
          </g>
          <g>
            <path
              :fill="fill"
              d="M23.5,14.2c-4.5,0-8.1,0-12.1,0c0-3.3,0-6.1,0-9.3c7.6,0,15.1,0,23.4,0c7.7,18.2,15.5,36.7,23.6,55.9
            c-4.5,0-7.9,0-11.7,0c-1.5-3.3-2.9-6.5-4.5-10c-7.7,0-15.1,0-22.9,0c-1.5,3.3-3,6.6-4.6,10c-3.6,0-7.1,0-11.5,0
            C10.1,45.2,16.6,30.1,23.5,14.2z M30.9,21.9c-2.7,6.7-5,12.3-7.4,18.3c5.4,0,9.7,0,14.7,0C35.8,34.2,33.7,28.8,30.9,21.9z"
            />
          </g>
          <g>
            <path
              :fill="fill"
              d="M124.4,26.6c0,0,6.3,0,6.3,0c0-3.5,0-7.5,0-10.9c0-3.5,0-6.9,0-10.8c14.5,0,28.5,0,42.9,0c0,3.1,0,6.2,0,10
            c-10.4,0-20.7,0-31.4,0c0,4.1,0,7.5,0,11.8c7,0,14.1,0,21.5,0c0,3.8,0,6.8,0,10.5c-7.1,0-14.2,0-21.8,0c0,8.2,0,15.6,0,23.5
            c-3.8,0-7.1,0-11,0c0-7.5,0-15.8,0-23.5c0,0-6.4,0-6.5,0C124.4,34,124.4,29.9,124.4,26.6z"
            />
          </g>
          <g>
            <path :fill="fill" d="M182,37c0,0-6.5,0-6.5,0V26.6" />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'agfeo-logo',

  props: {
    fill: {
      type: String,
      default: '#002181',
    }
  },
}
</script>
