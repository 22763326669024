<template>
  <div class="app-footer--wrap">
    <v-footer
      class="bg-primary py-15"
    >
      <v-container>
        <v-row dense>
          <v-col
            :cols="12"
            :md="12"
            :lg="3"
            align-self="center"
          >
            <v-img
              src="/images/logo-mono.png"
              width="200"
              height="110"
              contain
              class="mb-10 mb-lg-0 mx-auto mx-sm-0"
            />
          </v-col>
          <v-col
            :cols="12"
            :sm="5"
            :lg="3"
            class="text-center text-sm-left mb-10 mb-sm-0"
          >
            <div id="contact-heading" class="text-h6 pl-sm-4">
              Kontakt
            </div>
            <v-list density="compact" bg-color="transparent" aria-labelledby="contact-heading">
              <v-list-item role="option" title="AGFEO GmbH & Co. KG" />
              <v-list-item role="option" title="33647 Bielefeld" />
              <v-list-item role="option" title="Telefon: +49 521 44709-0" link />
              <v-list-item role="option" title="Fax: +49 521 44709-98555" link />
              <v-list-item role="option" title="E-Mail: info@agfeo.de" href="info@agfeo.de" />
            </v-list>
          </v-col>
          <v-col
            :cols="12"
            :sm="3"
            :lg="3"
            class="text-center text-sm-left mb-10 mb-sm-0"
          >
            <div id="service-heading" class="text-h6 pl-sm-4">
              Service
            </div>
            <v-list density="compact" bg-color="transparent" aria-labelledby="service-heading">
              <v-list-item role="option" title="FAQ" :to="{ name: 'faq' }" />
              <v-list-item role="option" title="Datenschutz" :to="{ name: 'data-protection' }" />
              <v-list-item role="option" title="Impressum" :to="{ name: 'imprint' }" />
            </v-list>
          </v-col>
          <v-col
            :cols="12"
            :sm="4"
            :lg="3"
            class="text-center text-sm-left mb-10 mb-sm-0"
          >
            <div id="social-heading" class="text-h6 pl-sm-4">
              Social Media
            </div>
            <v-list density="compact" bg-color="transparent" aria-labelledby="social-heading">
              <v-list-item role="option" title="YouTube" href="https://www.youtube.com/AGFEOde">
                <template #prepend>
                  <youtube-icon class="d-none d-sm-flex social-icon mr-4" />
                </template>
              </v-list-item>
              <v-list-item role="option" title="facebook" href="https://www.facebook.com/agfeo">
                <template #prepend>
                  <facebook-icon class="d-none d-sm-flex social-icon mr-4" />
                </template>
              </v-list-item>
              <v-list-item role="option" title="twitter" href="https://twitter.com/agfeode?lang=de">
                <template #prepend>
                  <twitter-icon class="d-none d-sm-flex social-icon mr-4" />
                </template>
              </v-list-item>
              <v-list-item role="option" title="LinkedIn" href="https://www.linkedin.com/company/agfeo-gmbh-&-co-kg/">
                <template #prepend>
                  <linkedin-icon class="d-none d-sm-flex social-icon mr-4" />
                </template>
              </v-list-item>
              <v-list-item role="option" title="Xing" href="https://www.xing.com/pages/agfeogmbh-co-kg">
                <template #prepend>
                  <xing-icon class="d-none d-sm-flex social-icon mr-4" />
                </template>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import FacebookIcon from '@/components/icons/social/FacebookIcon.vue'
import LinkedinIcon from '@/components/icons/social/LinkedinIcon.vue'
import TwitterIcon from '@/components/icons/social/TwitterIcon.vue'
import XingIcon from '@/components/icons/social/XingIcon.vue'
import YoutubeIcon from '@/components/icons/social/YoutubeIcon.vue'

export default {
  name: 'app-footer',

  components: {
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    XingIcon,
    YoutubeIcon,
  },
}
</script>

<style lang="scss">
  .app-footer--wrap {
    .v-container {
      position: relative;
      z-index: 1;
    }

    .social-icon {
      width: 24px;

      svg {
        fill: #fff;
        flex: 1;
      }
    }
  }
</style>
