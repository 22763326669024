<template>
  <div>
    <p>
      Unser Audio Informations- und Management-System (AIS/AMS) stellt Euch umfassende Wartefeld-Technologien bereit. Lasst Eure Anrufer automatisch vom HyperFonie System begrüßen und schöpft die zahlreichen Möglichkeiten unserer Anrufer-Interaktionsmöglichkeiten aus. Dabei können Wartefelder gänzlich losgelöst von anderen Kalenderfunktionen nach Euren individuellen Vorgaben zeitgesteuert und bedarfsweise aktiviert werden. Selbstverständlich ist eine Visualisierung evtl. wartender Anrufer jederzeit möglich. Natürlich inkl. der Möglichkeit einzelne Wartende vorab und manuell aus dem Wartefeld zu holen. Und das ganz uneingeschränkt auch über unsere AGFEO Systemtelefone, wie es sich für einen Systemanbieter gehört und nicht nur über die PC-Software. Damit Ihr den Überblick behaltet, könnt Ihr zur Nachbearbeitung jederzeit auch Statistikdaten über die Wartefelder abrufen oder vollkommen automatisiert erhalten.
    </p>
  </div>
</template>

<script>
export default {
  name: 'welcome-statistics',
}
</script>
