<template>
  <div>
    <p>
      Mit der HyperFonie Cloud Telefonanlage sparen Sie richtig. Es braucht keine Investition vor Ort. Nutzen Sie bequem den vorhandenen Internetzugang und binden Sie Ihr Team vollkommen standortunabhängig ein.
    </p>

    <ul class="my-4">
      <li>
        Abrechnung nur pro User
      </li>
      <li>
        Der Administrative User ist stets kostenfrei
      </li>
      <li>
        Anbindung an DATEV Telefonie Basis optional
      </li>
      <li>
        Premium Support durch AGFEO optional
      </li>
      <li>
        Backup Option "täglich" optional
      </li>
    </ul>

    <p class="mb-4">
      Und alles ist bereits monatlich kündbar. Als HyperFonie Kunde kann jederzeit der Ausbau skaliert werden! Mal "mehr", mal "weniger"? Geht! Und mit unseren günstigen Laufzeittarifen sparen Sie nochmals über 20%!
    </p>

    <p>
      Das war's. <br>
      Es gibt keinerlei andere / versteckte Kosten!
    </p>
  </div>
</template>

<script>
export default {
  name: 'licence-consts',
}
</script>
