import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { aliases, md } from 'vuetify/iconsets/md'
import { de } from 'vuetify/locale'
// import * as components from 'vuetify/components'
// import * as directives from 'vuetify/directives'

import { VDataTable } from 'vuetify/labs/VDataTable'
import { VOtpInput } from 'vuetify/labs/VOtpInput'

import colors from 'vuetify/lib/util/colors'

export default createVuetify({
  components: {
    // ...components,
    VDataTable,
    VOtpInput,
  },
  // directives,
  icons: {
    defaultSet: 'md',
    aliases,
    sets: {
      md,
    },
  },
  locale: {
    locale: 'de',
    messages: { de }
  },
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#243a64',
          'primary-lighten-1': '#2f5093',
          secondary: colors.blue.accent4,
        },
      },
    },
  },
})
