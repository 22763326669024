import keycloak from '../plugins/keycloak'

export default {
  baseUrl: '/api',
  baseHeader: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-requested-with': 'XMLHttpRequest',
  },

  /**
   * Request-wrap for global settings, interceptions,..
   *
   * @param {string} url
   * @param {object} options
   * @param {string} options.method
   * @param {any} options.body
   * @param {object} options.headers Additional headers (can override the default)
   * @returns {Promise}
   */
  async baseFetch (url = '', { method = 'GET', body, headers = {} } = {}) {
    try {
      await keycloak.instance.updateToken()
    } catch (error) {
      console.error('Failed to refresh token:', error)
      return keycloak.login()
    }

    return await fetch(`${this.baseUrl}/${url}`, {
      headers: {
        ...this.baseHeader,
        ...headers,
        authorization: `Bearer ${keycloak.instance.token}`
      },
      method,
      body,
    })
  },
}
