<template>
  <div class="icon-text-flipper--wrap mb-4">
    <div class="flipper" />
  </div>
</template>

<script>
export default {
  name: 'icon-text-flipper',
}
</script>

<style lang="scss">
  .icon-text-flipper--wrap {
    .flipper {
      width: 80px;
      height: 80px;
      animation: Imageflip 10s infinite;
      transform-style: preserve-3d;
      background-size: cover;
    }

    @keyframes Imageflip {
      0% {
        -webkit-transform: rotateY(0deg);
        background-image: url('/headset.svg');
      }

      25% {
        -webkit-transform: rotateY(180deg);
        background-image: url('/monitor.svg');
      }

      50% {
        -webkit-transform: rotateY(0deg);
        background-image: url('/phone-call.svg');
      }

      75% {
        -webkit-transform: rotateY(180deg);
        background-image: url('/sitemap.svg');
      }

      100% {
        -webkit-transform: rotateY(0deg);
        background-image: url('/headset.svg');
      }
    }
  }
</style>
