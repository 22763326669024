<template>
  <div class="company-differentiation-alt--wrap">
    <v-row>
      <v-col
        v-for="(claim, i) in claims"
        :key="i"
        :cols="12"
        class="d-flex"
      >
        <v-card
          rounded="0"
          min-height="220"
          color="white"
          :elevation="0"
          class="is--hover-card"
        >
          <div class="d-flex flex-column flex-md-row">
            <v-sheet min-width="250" class="px-15 py-10 pa-md-2 flex-grow-0 flex-shrink-0 ma-md-auto">
              <component :is="claim.icon" class="card-icon" />
            </v-sheet>
            <div class="pa-4 d-flex flex-column justify-center">
              <v-card-title class="text-center text-sm-left text-h5 font-exo font-weight-bold">
                {{ claim.title }}
              </v-card-title>
              <div class="text-center text-sm-left text-h6 pa-4 font-weight-regular">
                {{ claim.description }}
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UndrawCollab from '@/components/icons/UndrawCollab.vue'
import UndrawDreamer from '@/components/icons/UndrawDreamer.vue'
import UndrawProjections from '@/components/icons/UndrawProjections.vue'
import UndrawTeam from '@/components/icons/UndrawTeam.vue'
import UndrawWorking from '@/components/icons/UndrawWorking.vue'

export default {
  name: 'company-differentiation-alt',

  components: {
    UndrawCollab,
    UndrawDreamer,
    UndrawProjections,
    UndrawTeam,
    UndrawWorking,
  },

  data () {
    return {
      claims: [
        {
          title: 'Einfach',
          description: 'Einfache Strukturen sowie einfache Abläufe bilden bei AGFEO die Grundlage für Maßgeschneiderte Lösungen. Die Marke steht für konsequente Kundenorientierung, Führung ohne unnötige Hierarchien und Produkte mit klarem Design und einfacher Bedienung.',
          color: '',
          icon: 'UndrawTeam',
        },
        {
          title: 'Perfekt',
          description: 'Perfektion und Leidenschaft sind die Motivation für AGFEO. Besser zu sein ist der Grundsatz, um Kunden für AGFEO-Produkte zu begeistern und langfristig Freude zu schenken. AGFEO ist ein Familienunternehmen und stellt sich der sozialen Verantwortung für Mitarbeiter und deren Familien.',
          color: '',
          icon: 'UndrawCollab',
        },
        {
          title: 'Kommunikativ',
          description: 'Kommunikation ist der Kern von AGFEO – der Dialog eine der wichtigsten Grundregeln. Kommunikation erzeugt Miteinander, Treue und gegenseitiges Vertrauen: ob Kunden oder Partner, Geschäftsleitung oder Mitarbeiter.',
          color: '',
          icon: 'UndrawWorking',
        },
        {
          title: 'Nachhaltig',
          description: 'AGFEO ist »Made in Germany« und sichert so die Qualitätsführerschaft des Produktionsstandortes Deutschland. Alle Unternehmensabläufe, insbesondere in der Produktion, sind ressourcenschonend strukturiert, werden permanent überprüft und, wenn nötig, optimiert.',
          color: '',
          icon: 'UndrawDreamer',
        },
        {
          title: 'Zukunftsweisend',
          description: 'Mit einer über 70-jährigen Tradition verpflichtet sich jede Generation der Zukunft des Unternehmens. AGFEO-Kommunikationslösungen verbinden den aktuellen Stand der Technik mit den modernsten Zukunftstechnologien.',
          color: '',
          icon: 'UndrawProjections',
        },
      ]
    }
  }
}
</script>
