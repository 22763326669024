<template>
  <div class="claims-view--wrap">
    <v-sheet class="position-relative rounded-0 claim-sheet rounded-xl mb-15 pa-8 pa-sm-15 mb-15">
      <hyper-heading
        title="Flexibel ohne Einschränkungen"
        subtitle="Einzigartig am Markt"
        class="mb-8 mb-sm-15"
      />

      <template
        v-for="(claim, i) in claims"
        :key="i"
      >
        <h3
          class="text-center text-sm-left text-h4 font-exo font-weight-bold mb-2"
          :class="{
            'text-primary': claim.id === expanded
          }"
        >
          {{ claim.title }}
        </h3>
        <h3 class="text-center text-sm-left text-h5 font-exo font-weight-bold medium-opacity">
          {{ claim.subtitle }}
        </h3>
        <v-expand-transition>
          <div v-if="claim.id === expanded">
            <div class="mt-6" v-html="claim.description" />
          </div>
        </v-expand-transition>
        <v-btn
          variant="outlined"
          color="primary"
          class="mx-auto mx-sm-0 mt-6"
          :append-icon="claim.id === expanded ? 'expand_less' : 'expand_more'"
          @click="expanded = claim.id === expanded ? null : claim.id"
        >
          {{ claim.id === expanded ? 'Weniger anzeigen' : 'Mehr erfahren' }}
        </v-btn>
        <v-divider
          v-if="i < claims.length - 1"
          class="my-5 my-sm-10"
        />
      </template>
    </v-sheet>
  </div>
</template>

<script>
import HyperHeading from '@/components/HyperHeading.vue'

export default {
  name: 'claims-view',

  components: {
    HyperHeading,
  },

  data () {
    return {
      claims: [
        {
          id: 1,
          title: 'Volle Kostenkontrolle, monatlich kündbar',
          subtitle: 'Transparente, günstige Konditionen - von Beginn an!',
          description: 'Keine hohe Anfangsinvestitionen für Hardware und Infrastruktur, keine laufenden Wartungskosten, keine zusätzliche Lizenzgebühren: Mit der HyperFonie zahlen Sie monatlich nur die Anzahl der freigeschalteten User, alle anderen Funktionen sind integriert!',
        },
        {
          id: 2,
          title: 'Flexibilität in allen Bereichen',
          subtitle: 'Skalierbar von 5 bis 250 User, Standortunabhängig, Providerunabhängig',
          description: `
Die HyperFonie ist flexibel in allen Bereichen: Buchen Sie User dazu, wenn Sie zusätzliche Telefonarbeitsplätze benötigen, kündigen Sie einzelne User, wenn diese nicht mehr benötigt werden, alles auf monatlicher Basis. Doch Flexibilität ist mehr als nur die Skalierbarkeit der Useranzahl: Nutzen Sie die HyperFonie von jedem Ort der Welt - die einzige Einschränkung ist die Qualität Ihres Internetzugangs.
<br>
Auch setzt die HyperFonie Ihnen (fast) keine Grenzen, wenn es um die Auswahl Ihres SIP-Providers geht. AGFEO hat bereits über 300 SIP-Provider getestet und entsprechende Templates angepasst. Auf Grund der umfassenden Konfigurationsmöglichkeiten können Sie auch SIP-Provider nutzen, für die noch kein Template zur Verfügung steht. Und die HyperFonie setzt in Punkto Flexibilität noch einen drauf: Sie können bis zu 32 unterschiedliche SIP-Konten anlegen, somit bis zu 32 SIP-Provider gleichzeitig nutzen!
`,
        },
        {
          id: 3,
          title: 'AGFEO Quality Cloud',
          subtitle: 'TÜV Zertifizierung, Redundanz, Verschlüsselung und Hosting in Deutschland/Bielefeld',
          description: 'Die AGFEO Quality Cloud zeichnet sich durch mehrere entscheidende Merkmale aus: Zum einen ist das Rechenzentrum TÜV-zertifiziert und als „Reliable Data Center“ ausgezeichnet, eine Zertifizierung, die regelmäßig erneuert wird. Zum anderen ist das in Bielefeld (de) ansässige Datenzentrum sowohl standort- als auch leitungsredundant, um höchstmögliche Ausfallsicherheit zu garantieren. Darüber hinaus sind sowohl die Daten als auch die Verbindungen verschlüsselt. Und die DSGVO Konformität versteht sich von selbst!',
        },
        {
          id: 4,
          title: 'Einfache Wartung des Systems und großes Partnernetzwerk',
          subtitle: 'Die beste Wartung ist die, die Sie nicht übernehmen müssen!',
          description: 'Deshalb haben Sie mit der Wartung des Systems auch nichts zu tun. Denn für alles, was in der Cloud an Wartung anfällt, ist der Cloudanbieter zuständig. Und sollten Sie doch einmal vor Ort ein kleines Problem haben: Nutzen Sie unser großes Partnernetzwerk. Da AGFEO schon seit Jahrzehnten Erfahrung im Bereich der Telefonie und dadurch ein großes Partnernetzwerk aufgebaut hat, ist auch Ihr Ansprechpartner „nur wenige Schritte“ entfernt.',
        },
        {
          id: 5,
          title: 'Systemfunktionalität',
          subtitle: 'Bei AGFEO hat die Kommunikation System!',
          description: 'Auch mit der HyperFonie genießen Sie die AGFEO Systemfunktionalität. Sehen Sie im AGFEO Dashboard, wer telefoniert, erreichbar ist oder gerade abwesend. Schalten Sie Rufumleitungen für alle Nutzer, für die Sie die Berechtigung haben, nutzen Sie den Komfort einer globalen Anrufliste, wählen Sie aus, mit welcher Linie Sie nach extern telefonieren wollen und vieles mehr!',
        },
      ],
      expanded: null,
    }
  },
}
</script>

<style lang="scss">
  .claims-view--wrap {
    .medium-opacity {
      opacity: 0.5;
    }
  }
</style>
