<template>
  <div class="app-header--wrap">
    <v-app-bar
      v-scroll="onScroll"
      height="74"
      floating
      :elevation="elevateHeader ? 8 : 0"
    >
      <div class="agfeo-colors" />

      <v-container>
        <div class="d-flex align-center">
          <router-link :to="{ name: 'home' }" aria-label="Zur Startseite wechseln">
            <v-img
              src="/images/logo-red-cut.png"
              width="170"
              height="74"
              contain
              alt="agfeo HyperFonie"
              class="mr-4 flex-grow-0 hypervoice-logo"
            />
          </router-link>

          <v-tabs
            class="d-none d-md-flex ml-auto top-indicator"
            color="primary"
            slider-size="5"
          >
            <v-tab
              v-for="({ icon, title, route }, i) in navEntries"
              :key="i"
              class="px-6 font-weight-bold"
              :to="{ name: route }"
              :prepend-icon="icon"
              exact
            >
              {{ title }}
            </v-tab>
          </v-tabs>

          <v-menu width="220">
            <template #activator="{ props }">
              <v-btn icon="menu" v-bind="props" class="ml-auto d-md-none" />
            </template>

            <v-list>
              <v-list-item
                v-for="({ icon, title, route }, i) in navEntries"
                :key="i"
                :title="title"
                :prepend-icon="icon"
                :to="{ name: route }"
              />
            </v-list>
          </v-menu>
        </div>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useUiStore } from '@/stores/ui.js'

export default {
  name: 'app-header',

  data () {
    return {
      elevateHeader: false,
    }
  },

  computed: {
    ...mapState(useUiStore, ['showLogin']),

    navEntries () {
      const entries = [
        {
          icon: 'window',
          title: 'Start',
          route: 'home',
        },
        {
          icon: 'dynamic_form',
          title: 'Features',
          route: 'features',
        },
        {
          icon: 'help',
          title: 'FAQ',
          route: 'faq',
        },
        {
          icon: 'lightbulb',
          title: 'About',
          route: 'about',
        },
      ]

      const loginEntry = {
        icon: 'account_circle',
        title: 'Login',
        route: 'login',
      }

      return this.showLogin
        ? [...entries, loginEntry]
        : entries
    },
  },

  methods: {
    onScroll (e) {
      this.elevateHeader = e.target.documentElement.scrollTop >= 60
    },
  },
}
</script>

<style lang="scss">
  .app-header--wrap {
    position: relative;

    .v-toolbar {
      transition-duration: 0.28s;
      transition-property: box-shadow;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      overflow: visible;
    }

    .hypervoice-logo {
      .v-img__img--contain {
        object-position: left center;
      }
    }

    .v-tabs {
      &.top-indicator {
        .v-tab__slider {
          top: 0;
          bottom: auto;
        }
      }
    }

    .agfeo-colors {
      position: absolute;
      left: 0;
      bottom: -5px;
      right: 0;
      display: flex;
      animation: 1s ease-out 0s 1 slideInFromLeft;

      &::before,
      &::after {
        content: '';
        height: 5px;
        background-color: transparent;
      }

      &::before {
        width: 80%;
        background-color: var(--agfeo-red);
      }

      &::after {
        width: 20%;
        background-color: var(--agfeo-yellow);
      }
    }

    @keyframes slideInFromLeft {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0);
      }
    }
  }
</style>
