<template>
  <div>
    <p>
      Das Hosting der HyperFonie ist <br>
      MADE IN BIELEFELD
    </p>

    <ul class="my-4">
      <li>
        Im TÜV zertifizierten Rechenzentrum
      </li>
      <li>
        Standortredundant
      </li>
      <li>
        Leitungsredundant
      </li>
      <li>
        Bietet eine Failover-Absicherung bei Ausfall
      </li>
      <li>
        Und natürlich DSGVO-konform (es gelten deutsche Gesetze und Bestimmungen)
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'security-cloud',
}
</script>
