import Keycloak from 'keycloak-js'
import router from '@/router/index.js'

export default {
  config: null,
  instance: null,

  /**
   * We have to tell the keycloak the url to redirect to after an
   * authentication. Keycloak adds several url-parameters to that one and
   * starts directly with a '&' - so we add a first (otherwise useless)
   * parameter.
   *
   * @returns {Promise}
   */
  async login () {
    await this.init()

    // when coming from the login-view we want to redirect to the dashboard
    const { href: dashboardHref } = router.resolve({ name: 'dashboard' })

    // there may be a desired target (e.g. when reloading or when entering an
    // url directly) - then we want to use that one instead of the dashboard
    const { href: fromHref, query: fromQuery } = router.currentRoute.value || {}
    const target = fromHref.includes('login') ? dashboardHref : fromHref

    // if the desired target already has url-params we don't need our ugly
    // workaround (keycloak adds parameters always with a leading & - even if
    // there aren't ony others present)
    const hasQueryParams = Object.keys(fromQuery || {}).length > 0

    const redirectTarget = new URL(target, window.location.origin).href
    return this.instance.login({ redirectUri: `${redirectTarget}${hasQueryParams ? '' : '?kc=1'}` })
  },

  async logout () {
    const { href } = router.resolve({ name: 'login' })

    this.instance.logout({
      redirectUri: new URL(href, window.location.origin).href
    })
  },

  /**
   * @returns {Promise}
   */
  async init () {
    this.instance = new Keycloak(this.config)

    if (this.instance.didInitialize) {
      return this.instance.authenticated
    }

    return this.instance.init({ checkLoginIframe: false })
  },

  debugToken () {
    if (!this.instance.tokenParsed) {
      return 'Not authenticated'
    }

    let o = 'Token Expires:\t\t' + new Date((this.instance.tokenParsed.exp + this.instance.timeSkew) * 1000).toLocaleString() + '\n'
    o += 'Token Expires in:\t' + Math.round(this.instance.tokenParsed.exp + this.instance.timeSkew - new Date().getTime() / 1000) + ' seconds\n'

    if (this.instance.refreshTokenParsed) {
      o += 'Refresh Token Expires:\t' + new Date((this.instance.refreshTokenParsed.exp + this.instance.timeSkew) * 1000).toLocaleString() + '\n'
      o += 'Refresh Expires in:\t' + Math.round(this.instance.refreshTokenParsed.exp + this.instance.timeSkew - new Date().getTime() / 1000) + ' seconds'
    }

    return o
  }
}
