<template>
  <div class="plans-grid--wrap">
    <hyper-heading
      :title="`Der passende Tarif für ${userMinimum} - ${userMaximum} User`"
      subtitle="Für jeden Anwendungsfall"
      class="mb-6 mb-sm-12"
    />

    <div class="d-flex flex-wrap text-center text-lg-left justify-center justify-lg-start align-center mb-6 mb-lg-12">
      <h2 class="text-h3 font-exo font-weight-bold mr-md-10">
        Tarifrechner
      </h2>
      <div class="d-flex flex-wrap justify-center justify-md-start align-center">
        <div class="text-h4 font-exo mb-0 my-lg-4">
          Anzahl User
        </div>
        <v-text-field
          v-model.number="enteredUsers"
          density="comfortable"
          variant="outlined"
          color="primary"
          hide-details
          type="number"
          :min="userMinimum"
          :max="userMaximum"
          :error="enteredUsers && (enteredUsers < userMinimum || enteredUsers > userMaximum)"
          class="user-input mx-4"
          @blur="enforceMinMaxValues()"
        />
      </div>
    </div>

    <v-row class="plans--row">
      <v-col
        v-for="(plan, i) in plans"
        :key="i"
        :cols="12"
        :lg="4"
      >
        <v-hover>
          <template #default="{ isHovering, props }">
            <v-card
              v-bind="props"
              :rounded="isHovering ? 0 : 'xl'"
              :color="plan.color"
              :elevation="isHovering ? 20 : 0"
              class="d-flex flex-column flex-grow-1"
            >
              <v-card-title class="text-center pt-2 pb-0 py-sm-4 py-lg-12">
                <div class="text-h4 font-weight-bold font-exo mb-n2 mb-sm-1">
                  Laufzeit
                </div>
                <div class="text-h3 font-weight-bold font-exo">
                  {{ plan.title }}
                </div>
              </v-card-title>

              <div class="plan-subtitle text-center text-h4 font-exo px-2 px-lg-8 mb-2 mb-sm-4 mb-lg-12">
                {{ plan.subtitle }}
              </div>

              <div class="d-lg-none">
                <plan-prices
                  :plan="plan"
                  :entered-users="enteredUsers"
                  :additional-options-price="additionalOptionsPrice"
                />
              </div>
              <v-divider class="mt-auto" />
              <v-card-actions class="pa-md-4">
                <v-btn
                  append-icon="unfold_more"
                  class="font-exo mx-auto mx-sm-0"
                  @click="plan.reveal = true"
                >
                  Mehr erfahren
                </v-btn>
              </v-card-actions>

              <v-sheet
                v-if="plan.advantage"
                :color="plan.advantage.bgColor"
                class="plan-advantage font-exo"
                :class="`text-${plan.advantage.textColor}`"
              >
                Sie sparen über
                <div class="text-h6 font-weight-bold font-exo d-inline-block d-sm-block">
                  {{ plan.advantage.value }}
                </div>
              </v-sheet>

              <v-expand-transition>
                <v-card
                  v-if="plan.reveal"
                  class="v-card--reveal d-flex flex-column border"
                  color="grey-lighten-4"
                  :rounded="isHovering ? 0 : 'xl'"
                  style="height: 100%;"
                >
                  <v-card-text class="pa-2 pa-md-4 pb-0 d-flex flex-column text-center">
                    <p
                      class="text-h4 py-md-2 font-exo font-weight-bold text-agfeo-red"
                    >
                      {{ plan.title }}
                    </p>
                    <p
                      class="text-body-1 mx-md-n1"
                      v-html="plan.description"
                    />
                  </v-card-text>
                  <v-divider :color="plan.color" class="mt-auto" />
                  <v-card-actions class="mt-auto pa-2 pa-sm-4">
                    <v-btn
                      variant="elevated"
                      flat
                      :color="plan.color"
                      class="font-exo"
                      append-icon="unfold_less"
                      @click="plan.reveal = false"
                    >
                      Schließen
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-card>
          </template>
        </v-hover>

        <div class="text-center font-exo mt-2 mt-sm-4">
          {{ plan.hint }}
        </div>
      </v-col>
    </v-row>

    <v-sheet
      color="agfeo-red"
      rounded="xl"
      class="additional-options--wrap pa-8 my-8"
    >
      <v-row align="center">
        <v-col :cols="12" :sm="12" :lg="4">
          <div class="font-exo font-weight-bold text-h4">
            Zusatzoptionen
          </div>
        </v-col>
        <v-col :cols="12" :sm="12" :lg="8">
          <additional-options-choice
            v-model="chosenOptions"
            class="d-flex flex-wrap justify-space-between"
          />
        </v-col>
      </v-row>
    </v-sheet>

    <v-sheet
      color="transparent"
      class="mt-8 mt-sm-15 price-calculation--wrap d-none d-lg-block"
    >
      <v-row v-if="enteredUsers >= userMinimum && enteredUsers <= userMaximum">
        <v-col
          v-for="(plan, i) in plans"
          :key="i"
          :cols="12"
          :lg="4"
          class="d-flex flex-column"
        >
          <v-card
            rounded="xl"
            :color="plan.color"
            :elevation="0"
            class="flex-grow-1 d-flex flex-column"
          >
            <plan-prices
              :plan="plan"
              :entered-users="enteredUsers"
              :additional-options-price="additionalOptionsPrice"
            />
          </v-card>
        </v-col>
      </v-row>
      <div v-else class="my-4 text-center text-h6">
        Bitte geben Sie eine Anzahl zwischen {{ userMinimum }} und {{ userMaximum }} ein.
      </div>
    </v-sheet>
  </div>
</template>

<script>
import AdditionalOptionsChoice from '@/components/RateCalculator/AdditionalOptionsChoice.vue'
import HyperHeading from '@/components/HyperHeading.vue'
import PlanPrices from '@/components/RateCalculator/PlanPrices.vue'

const userMinimum = 5

const monthlyPlan = {
  title: '1 Monat*',
  subtitle: 'monatliche Abrechnung',
  description: 'Bleiben Sie flexibel! Mit nur 1 Monat Laufzeit können Sie jederzeit Ihre benötigten User frei skalieren. Kündbar jeweils zum Ende des nächsten Monats. Ein Wechsel in unsere Laufzeit-Tarife ist übrigens problemlos möglich.',
  advantage: null,
  color: '#999999',
  hint: '*monatlich kündbar',
  reveal: false,
  prices: {
    perUser: {
      5: 9.9,
      50: 9.5,
      90: 9.1,
      150: 8.7,
    },
    yearly: false,
  },
  referencePlan: null,
}

export default {
  name: 'plans-grid',

  components: {
    AdditionalOptionsChoice,
    HyperHeading,
    PlanPrices,
  },

  data () {
    return {
      chosenOptions: [],
      enteredUsers: userMinimum,
      userMinimum,
      userMaximum: 250,
      plans: [
        monthlyPlan,
        {
          title: '12 Monate**',
          subtitle: 'monatliche Abrechnung',
          description: 'Nutzen Sie unseren Laufzeittarif für 12 Monate mit monatlicher Rechnung und sparen Sie zum 1 Monatstarif über 10%. Übrigens können Sie jederzeit noch weitere User im 1 Monatstarif für Saisongeschäft etc. dazu buchen. Der Vertrag ist kündbar mit einer Frist von 3 Monaten zum Ende der Laufzeit.',
          advantage: {
            bgColor: 'agfeo-yellow-light',
            textColor: 'agfeo-red',
            value: '10%',
          },
          color: '#737373',
          hint: '**jährlich kündbar',
          reveal: false,
          prices: {
            perUser: {
              5: 8.9,
              50: 8.5,
              90: 8.2,
              150: 7.8,
            },
            yearly: false,
          },
          referencePlan: monthlyPlan,
        },
        {
          title: '12 Monate**',
          subtitle: 'jährliche Abrechnung',
          description: 'Nutzen Sie das höchste Sparpotential mit einer Laufzeit von 12 Monaten bei jährlicher Prepaid-Rechnung und sparen Sie zum 1 Monatstarif über 20%. Trotzdem können Sie jederzeit noch User im 1 Monatstarif dazu buchen. Der Vertrag ist kündbar mit Frist von 3 Monaten zum Ende der Laufzeit.',
          advantage: {
            bgColor: 'agfeo-red',
            textColor: 'white',
            value: '20%',
          },
          color: '#4D4D4D',
          hint: '**jährlich kündbar',
          reveal: false,
          prices: {
            perUser: {
              5: 7.9,
              50: 7.6,
              90: 7.2,
              150: 6.9,
            },
            yearly: true,
          },
          referencePlan: monthlyPlan,
        },
      ],
    }
  },

  computed: {
    additionalOptionsPrice () {
      return this.chosenOptions.reduce((sum, option) => sum + option.price, 0)
    },
  },

  mounted () {
    this.initFlashlight()
  },

  methods: {
    /**
     * When plan-cards get hovered we want to show a fancy flashlight. For that
     * we add the current mouseposition to the card-style. Based on that a
     * gradient gets rendered (see related scss).
     *
     * @returns {undefined}
     */
    initFlashlight () {
      const grid = document.querySelector('.plans--row')
      grid.onmousemove = e => {
        for (const card of grid.querySelectorAll('.v-card')) {
          const rect = card.getBoundingClientRect()
          const x = e.clientX - rect.left
          const y = e.clientY - rect.top

          card.style.setProperty('--mouse-x', `${x}px`)
          card.style.setProperty('--mouse-y', `${y}px`)
        }
      }
    },

    /**
     * When the user-amount gets set (or unset by deleting the content of the
     * input) we want to enforce the min-, max-values.
     *
     * @returns {undefined}
     */
    enforceMinMaxValues (val) {
      if (!this.enteredUsers || this.enteredUsers < this.userMinimum) {
        return (this.enteredUsers = this.userMinimum)
      }

      if (this.enteredUsers > this.userMaximum) {
        return (this.enteredUsers = this.userMaximum)
      }
    },
  }
}
</script>

<style lang="scss">
  .plans-grid--wrap {
    .v-card {
      transition-property: all;

        &:hover {
          transform: scale(1.02);
        }

        &:hover::before {
          opacity: 1;
        }

        &::before,
        &::after {
          border-radius: inherit;
          content: "";
          height: 100%;
          left: 0px;
          opacity: 0;
          position: absolute;
          top: 0px;
          z-index: 0;
          transition: opacity 500ms;
          width: 100%;
          pointer-events: none;
        }

        &::before {
          background: radial-gradient(
            600px circle at var(--mouse-x) var(--mouse-y),
            rgba(255, 255, 255, 0.3),
            transparent 40%
          );
          z-index: 3;
        }

        &::after {
          background: radial-gradient(
            600px circle at var(--mouse-x) var(--mouse-y),
            rgba(255, 255, 255, 0.0),
            transparent 40%
          );
          z-index: 1;
        }

      .v-card--reveal {
        bottom: 0;
        opacity: 1 !important;
        position: absolute;
        width: 100%;
        z-index: 10;

        .price-widget--wrap {
          position: relative;
          z-index: 10;

          .v-text-field {
            flex: 0 0 50%;
          }
        }
      }
    }

    .v-divider,
    .v-label {
      opacity: 1;
    }

    .user-input {
      flex: 0 1 100px;
      width: 100px;
      max-width: 95%;
    }

    .plan-advantage {
      padding: 12px;
      text-align: center;
    }
  }

  @media screen and (max-width: 1279px) {
    .plans-grid--wrap {
      .plan-subtitle {
        line-height: 1.75rem;
      }
    }
  }

  @media screen and (min-width: 600px) {
    .plans-grid--wrap {
      .plan-advantage {
        position: absolute;
        right: -80px;
        bottom: -60px;
        transform: rotate(-30deg);
        padding: 12px 60px 60px 115px;
      }
    }
  }
</style>
