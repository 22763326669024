<template>
  <div class="faq-view--wrap main-bg-gradient flex-grow-1">
    <v-container class="py-8 py-sm-15">
      <v-sheet class="pa-8 pa-sm-15 mb-15" rounded="xl">
        <hyper-heading
          title="FAQ"
          subtitle="Sie haben Fragen, wir Antworten"
          class="mb-8"
        />

        <v-text-field
          v-model="search"
          variant="solo"
          append-inner-icon="search"
          clearable
          placeholder="Suche"
          :class="filteredCategories.length > 0 ? 'mb-8' : 'mb-2'"
        />

        <div
          v-for="(category, i) in filteredCategories"
          :key="i"
          class="mb-14"
        >
          <h3 class="mb-7 text-h5 font-weight-bold">
            {{ category.title }}
          </h3>

          <v-expansion-panels variant="popout">
            <v-expansion-panel
              v-for="(entry, x) in category.entries"
              :key="`${i}_${x}`"
              ripple
              rounded="lg"
              role="button"
            >
              <template #title>
                <div class="text-h6" v-html="entry.question" />
              </template>
              <template #text>
                <div
                  class="text-h6 font-weight-regular py-4"
                  v-html="entry.answer"
                />
              </template>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>

        <v-sheet
          v-if="filteredCategories.length === 0"
          color="primary"
          class="px-4 py-2"
          rounded
        >
          Keine Treffer vorhanden.
        </v-sheet>
      </v-sheet>

      <learn-more />
    </v-container>
  </div>
</template>

<script>
import HyperHeading from '@/components/HyperHeading.vue'
import LearnMore from '@/components/LearnMore.vue'

export default {
  name: 'faq-view',

  components: {
    HyperHeading,
    LearnMore,
  },

  data () {
    return {
      search: '',

      categories: [
        {
          title: 'Allgemeine Fragen zur HyperFonie',
          entries: [
            {
              question: 'An wen richtet sich die HyperFonie und wer kann die HyperFonie erwerben?',
              answer: 'Die HyperFonie richtet sich ausschließlich an alle gewerblichen Kunden (z.B. juristische Personen und im Handelsregister eingetragene Kaufleute, Selbstständige, Freiberufler, Handwerksbetriebe etc.) mit Sitz in Deutschland. Der Erwerb und das Abschließen des Vertrags kann durch jeden dazu zeichnungsberechtigten Mitarbeiter vollzogen werden.',
            },
            {
              question: 'Was ist der Vorteil der HyperFonie Cloud-Telefonanlage?',
              answer: 'Die HyperFonie ist die Cloud-Telefonanlage von AGFEO. Dadurch sparen Sie sich die Investition in eine entsprechende Hardware und profitieren durch unsere automatische Überwachung und Wartung des Cloud-Systems, was Ihnen einen stets sicheren Betrieb gewährleistet. Durch den Verzicht einer physischen Anlage, sparen Sie sich die teure Einrichtung und Betreuung vor Ort und binden in Ihrem Unternehmen auch keine weiteren Ressourcen. Durch die Nutzung von VoIP-Verbindungen nutzen Sie alle Formen modernster Kommunikation und binden Arbeitsplätze einfach direkt über Ihr bestehendes Netzwerk mit ein. Dabei spielt es auch keine Rolle, ob sich diese im Unternehmen, Unterwegs oder Remote bzw. im HomeOffice arbeiten.',
            },
            {
              question: 'Was ist die Besonderheit der HyperFonie?',
              answer: `
AGFEO ist seit dem Jahr 1947 Experte mit langjähriger Erfahrung in moderner Kommunikation. Unsere Telefonanlagen setzen auch im Wettbewerbsvergleich sicherlich Maßstäbe hinsichtlich höchsten Komforts, ausgezeichneter Funktionalität – und dass bei gleichzeitig einfachster Bedienung.
<br><br>
Bei der Vermarktung und Support setzt AGFEO dabei auf den qualifizierten Fachhandelspartner vor Ort. Daher steht jedem HyperFonie Kunden ein AGFEO Fachhandelspartner als fester Ansprechpartner in der Region zur Seite. Dieser dient Ihnen vor Ort in enger und persönlicher Abstimmung zur individuellen Einrichtung anhand Ihrer Wünsche und Vorgaben und gewährt den bestmöglichen Support Ihrer HyperFonie. Somit sind Sie als HyperFonie Kunde nie allein und haben die Gewissheit jederzeit persönlich und optimal betreut zu werden.
    `,
            },
            {
              question: 'Was ist die HyperFonie Funktions-FLAT?',
              answer: 'Wir sind der Meinung, dass Sie als Anwender stets alle Funktionen und Leistungsmerkmale der HyperFonie nutzen sollten. Daher gibt es bei der HyperFonie auch keine „Ausbau-Editionen“ wie z.B. Basic, Advanced, Pro oder Enterprise etc. Nutzen Sie einfach jede in der HyperFonie möglichen Funktion. Denn jede von uns auch in der Zukunft entwickelte Funktionserweiterung der Anlage wird stets über die HyperFonie Funktions-FLAT bereitgestellt. So haben Sie die Gewährleistung JETZT und auch in der ZUKUNFT stets alle Leistungsmerkmale und Funktionen der Anlage anwenden zu können. Einzig gibt es optional zu buchbare Zusatzoptionen, die die Gesamtinstallation in bestimmten Bereichen ergänzen können. Dies gewährleistet Ihnen eine stetige Kostentransparenz. Ultimative Funktionalität. Ohne weitere Kosten und ohne böse Kosten-Überraschung.',
            },
            {
              question: 'Wie sicher ist Ihre Kommunikation über die HyperFonie?',
              answer: 'Für uns steht die Sicherheit an oberster Stelle. Daher ist jeglicher Datenverkehr von Ihnen zur HyperFonie z.B. zwangsweise und durchgängig stets nur verschlüsselt möglich. Zur Anmeldung und Authentifizierung am Verwaltungsportal der HyperFonie nutzen wir modernste OAuth-Verfahren und es kann optional eine 2-Faktor-Authentifizierung (2FA) aktiviert werden. Zudem erfolgt der Betrieb der HyperFonie in TÜV-zertifizierten Rechenzentren in Bielefeld. Unsere Cloudfarm ist standortredundant (mehrere Standorte!) und leitungsredundant ausgelegt. Die Verfügbarkeit beträgt im Jahresmittel zudem über 99%, so dass eine Nutzung Ihrer Kommunikation über unsere HyperFonie Cloud Telefonanlage nicht nur äußerst sicher, sondern auch sehr stabil ist.',
            },
            {
              question: 'Was ist die AGFEO Qualitäts-Cloud? Wo wird die Cloud-Telefonanlage gehostet?',
              answer: 'Ihr bei uns gebuchtes HyperFonie Cloud-PBX-System wird in der AGFEO Qualitäts-Cloud gehostet. Dieser Begriff kennzeichnet unser PREMIUM-Hosting in einem Bielefelder Rechenzentrum. Dieses ist TÜV-Zertifiziert, DSGVO-konform und nicht nur leitungsredundant, sondern auch standortredundant (mehrere Standorte!) und somit mehrfach abgesichert. Im Falle des Falles gibt es zudem ein Failover-Verfahren, das einen dauerhaft sicheren und stabilen Betrieb der Plattform sicherstellt. Jede HyperFonie Instanz verfügt als besonderes Hosting-Merkmal nicht nur über einen eindeutigen FQDN, sondern auch über eine dedizierte IP-Adresse. Darüber ist Ihre Instanz nicht nur weltweit erreichbar, sondern es können mit der HyperFonie auch solche Telefonprovider genutzt werden, die zur zusätzlichen Absicherung ein Whitelisting über eine feste, unveränderbare und somit eindeutige externe IP-Adresse vornehmen.',
            },
            {
              question: 'Was ist der Vorteil eines Vollsortimentlers?',
              answer: 'Mit einer AGFEO Kommunikationslösung erhalten Sie nicht nur das eigentliche Cloud-Telefonsystem, bestehend aus Telefonanlage, Software und Apps. Wir entwickeln und produzieren am Standort Bielefeld darüber hinaus auch die weitere benötigte Peripherie, wie schnurgebundene und schnurlose Telefone, HeadSets, Konferenzsysteme, Türstationen und mehr. Eigene Software und Endgeräte können dabei weitaus besser auf ein Kommunikationssystem abgestimmt werden, so dass Sie mit diesen und unserer HyperFonie von noch einfacherer Bedienung bei gleichzeitig mehr Funktionen und höherem Komfort als bei einfachen SIP-Telefonen und Wettbewerber-Lösungen profitieren. Und im Falle eines Falles, gibt es auch kein Kompetenzgerangel oder Ausreden zur Ursache. Eben „alles aus einer Hand“: AGFEO.',
            },
            {
              question: 'Welche Vorteile ergeben sich durch AGFEO Endgeräte?',
              answer: 'Heutige IP-Endgeräte basieren idR. technisch auf dem sog. „SIP“ Standard. Dieser stellt den kleinsten gemeinsamen Nenner dar, um mit Endgeräten Daten auszutauschen. Dagegen nutzen AGFEO Endgeräte mit „ASIP“ ein weitaus umfassenderes, eigenes Protokoll. Als Anwender profitieren Sie dadurch von einzigartigen Systemfunktionen (wie z.B. eine globale und gruppenfähige Anrufliste oder BLF-Funktionen mit wesentlich mehr Funktionalität etc.), die nur ein solcher Systemanbieter gewähren kann. Das bedeutet: Mit einer HyperFonie erhalten Sie das modernste CloudPBX-System aus dem Hause AGFEO und in Verbindung mit unseren eigenen Endgeräten haben Sie deutlich MEHR Komfort, MEHR Funktionen und das bei gleichzeitig EINFACHERER Bedienung.',
            },
          ],
        },
        {
          title: 'Fragen zum HyperFonie Nutzungsvertrag',
          entries: [
            {
              question: 'Was ist die Laufzeit des HyperFonie Nutzungsvertrags? Wie kann ich kündigen?',
              answer: `
<p>Die Vertragslaufzeit ist abhängig von Ihrem gewählten Tarifmodell und verlängert sich automatisch, sollte dieser nicht fristgerecht gekündigt werden.</p>
<p class="my-4">Es bestehen derzeit 3 Tarifoptionen:</p>
<p class="mb-4">
  <strong>Tarif "1 Monat":</strong><br>
  Der Vertrag hat eine Laufzeit von einem Monat. Die Rechnungsstellung erfolgt monatlich zu Beginn des jeweiligen Abrechnungszeitraums. Das Nutzungsverhältnis ist auf rein elektronischem Wege über das Verwaltungsportal von beiden Seiten kündbar mit einer Frist von einem Monat zum nächsten Monatsende.
</p>
<p class="mb-4">
  <strong>Tarif "12 Monate, monatliche Abrechnung" (Sparpotential >10%):</strong><br>
  Der Vertrag hat eine Laufzeit von 12 Monaten. Die Rechnungsstellung erfolgt monatlich zu Beginn des jeweiligen Abrechnungszeitraums. Das Nutzungsverhältnis ist auf rein elektronischem Wege über das Verwaltungsportal von beiden Seiten kündbar mit einer Frist von drei Monaten zum Ende der Laufzeit. Einzelne User können flexibel nachgebucht werden und werden über das Tarifmodell "1 Monat" abgerechnet. Dadurch kann weiterhin sehr flexibel eine saisonale Anpassung an den Bedarf erfolgen.
</p>
<p class="mb-4">
  <strong>Tarif "12 Monate, jährliche Abrechnung" (Sparpotential >20%):</strong><br>
  Der Vertrag hat eine Laufzeit von 12 Monaten. Die Rechnungsstellung erfolgt jährlich zu Beginn des jeweiligen Abrechnungszeitraums. Das Nutzungsverhältnis ist auf rein elektronischem Wege über das Verwaltungsportal von beiden Seiten kündbar mit einer Frist von drei Monaten zum Ende der Laufzeit. Einzelne User können flexibel nachgebucht werden und werden über das Tarifmodell "1 Monat" abgerechnet. Dadurch kann weiterhin sehr flexibel eine saisonale Anpassung an den Bedarf erfolgen.
</p>
<p class="mb-4">
  Unabhängig vom Tarifmodell der User beträgt die Laufzeit evtl. gebuchter Zusatzoptionen stets nur 1 Monat mit Kündigungsoption zum Ende des nächsten Monats. <br>
  Es gelten die zum Kauf- bzw. Verlängerungszeitpunkt jeweils gültigen Preise.
</p>
`,
            },
            {
              question: 'Was kostet mich die HyperFonie?',
              answer: 'Die Kosten der HyperFonie werden in Abhängigkeit der von Ihnen gebuchten User-Anzahl, evtl. aktivierten HyperFonie Zusatzoptionen und in Abhängigkeit des gewünschten Tarifmodell der User (1 Monat / 12 Monate mit monatlicher Rechnungsstellung / 12 Monate Prepaid und 1x jährlicher Rechnungsstellung) berechnet. Die Mindestanzahl an Usern beträgt 5. Der erste Monat wird immer tagesaktuell anteilig berechnet. Es gelten die zum Kauf- bzw. Verlängerungszeitpunkt jeweils gültigen Preise.',
            },
            {
              question: 'Sparpotential dank Laufzeittarifen',
              answer: 'Nutzen Sie unsere besonders günstigen Laufzeittarife für User, um zusätzlich zu sparen. Im Tarif "12 Monate, monatliche Abrechnung" erhalten Sie einen Rabatt von über 10% und im Tarif "12 Monate, jährlich" sogar über 20% auf den Userpreis.',
            },
            {
              question: 'Sparpotential bei größeren Installationen',
              answer: 'Größere Installationen, die z.B. mehr als 50 User benötigen, erhalten einen weiteren Rabatt auf den einzelnen Userpreis. Sie können so nochmals bis zu 8% sparen.',
            },
            {
              question: 'Tarif berechnen',
              answer: 'Über den Tarifrechner auf der Startseite können Sie Ihr individuelles Angebot direkt berechnen lassen.<br>Darüber hinaus fallen keine Kosten für die Wartung Ihrer technischen HyperFonie Cloud-Instanz oder für das Premium-Hosting in unserer AGFEO Qualitäts-Cloud an.',
            },
            {
              question: 'Wie setzen sich die Kosten der HyperFonie zusammen?',
              answer: 'Das fällige Nutzungsentgelt bestimmt sich aus den im Verwaltungsportal gebuchten Ausbauoptionen (auf Basis der Anzahl gewünschter „User“ und evtl. weiterer zusätzlich gebuchter Zusatzoptionen) in Abhängigkeit des gewählten Tarifmodell der User (1 Monat / 12 Monate mit monatlicher Rechnungsstellung / 12 Monate Prepaid und 1x jährlicher Rechnungsstellung), deren Preise zuzüglich der jeweils gültigen Umsatzsteuer im Verwaltungsportal der HyperFonie jeweils aktuell ausgewiesen werden.',
            },
          ]
        },
        {
          title: 'Technische Fragen zur HyperFonie',
          entries: [
            {
              question: 'Muss ich mit meinem Telefonanschluss zu AGFEO wechseln?',
              answer: `
NEIN! <br>
Ihre HyperFonie Cloud Telefonanlage ist bereits zu über 300 SIP Providern- und -Tarifen kompatibel! Nutzen Sie somit Ihren bisherigen Telefonanbieter einfach weiter, sofern es Ihr Provider erlaubt Ihren Telefonanschluss auch von anderen (fremden) Standort aus nutzen zu können (sog. „nomadische Nutzung“). Vor Wechsel auf die HyperFonie ist diese Fragestellung somit mit Ihrem bisherigen Anschlussanbieter abzuklären.
<br><br>
Alternativ stellt Ihnen gern unsere Schwestermarke AGFEOtel einen passenden und günstigen Telefonanschluss zur Verfügung.
<br><br>
Aber denken Sie daran: Bei einer HyperFonie sind Sie nicht allein und Ihr Ihnen zur Seite stehender AGFEO HyperFonie Fachhandelspartner wird Sie hierbei gerne begleiten und unterstützen!
`,
            },
            {
              question: 'Was ist das AGFEO Dashboard? Wie kann ich CTI/UC/VideoChats nutzen?',
              answer: `
Mit dem AGFEO Dashboard erhalten Anwender eines AGFEO Kommunikationssystem eine höchst-funktionale Software-Lösung. Sie dient dem User als Steuerungs- und Bedien-Cockpit der computerstützen Telefonie und unified communication (CTI/UC) und sichert darüber hinaus die Funktionalität als SoftPhone, bietet Zugang zum Videokonferenz- und Chat-System und visualisiert Anruflisten, den VoiceBox- und FaxBox- Eingang. Ergänzend integriert das AGFEO Dashboard zahlreiche externe Kontaktquellen wie z.B. Ihre Warenwirtschaft oder Branchensoftware inkl. MS Outlook, DATEV und vieler Praxensoftwarelösungen und ist zudem noch in der Lage Anruferinformationen an derzeit bereits über 150 fremde Softwareprodukte zu übergeben. Schalten Sie sich doch z.B. in Ihrer MS Teams Instanz am PC auf Besetzt, wenn Sie mit der HyperFonie am Telefonieren sind.
<br><br>
Das AGFEO Dashboard ist für MS Windows, Apple MacOS und optional Linux erhältlich. Für Android und Apple SmartPhones stehen APPs zur Verfügung. Es ist zu beachten, dass dabei Apps im Vergleich zum PC Client eingeschränkte Funktionalitäten aufweisen.
`,
            },
            {
              question: 'Wie kann ich ein Handy integrieren?',
              answer: `
Wir bieten mit unseren APPs für Android und Apple Smartphones entsprechende Apps an mit der die Nutzung vieler Leistungsmerkmale der HyperFonie Lösung auch bequem von unterwegs über das Handy genutzt werden können. Über die in den APPs integrierte FMC/OneNumber Funktion sind Sie zudem stets unter Ihrer Rufnummer erreichbar und können unterwegs vom Handy aus über Ihre HyperFonie Telefon Verbindungen mit Ihrer Bürorufnummer aufbauen, als wären Sie ein direkter User im Büro.
<br><br>
Aus Gründen der Bedienbarkeit und Übersicht ist die an den Apps nutzbare Funktionalität im Vergleich zum PC-Client eingeschränkt.
`,
            },
            {
              question: 'Wird MS Teams unterstützt?',
              answer: 'Über die PC-Variante des AGFEO Dashboard ist ein bidirektionaler Statusabgleich möglich. Vergessen Sie gegenseitige Störungen. In MS Teams „nicht erreichbar“, wenn mit der HyperFonie „im Gespräch“? Oder andersherum in der HyperFonie auf „besetzt“, wenn mit MS Teams „in Verbindung“? Geht. Die Lösung wurde in enger Zusammenarbeit mit einem externen Dienstleister entwickelt und kann dort kostengünstig bezogen werden. Zur Installation werden keine besonderen Kenntnisse benötigt.',
            },
            {
              question: 'Kann ich meine Softwareprodukte einbinden?',
              answer: `
Mit dem AGFEO Dashboard (s.dort) können problemlos externe Kontaktquellen zur direkten Integration eingebunden werden. Dabei stehen wie bei einem „meta-Directory“ gleichzeitig mehrere Schnittstellen zur Verfügung, die auch gleichzeitig und mehrfach genutzt werden können. Auf diese Weise können über ODBC-Technologien z.B. Datenbanken Ihres CRM-Systems, Warenwirtschaft oder Branchenlösung eingelesen werden. Mit der LDAP-Schnittstelle werden Verzeichnisdienste angebunden und darüber hinaus können die Daten von MS Outlook, DATEV (zentraler Stammdatendienst und Telefonie Basis), Google Accounts und weitere direkt mit dem PC-Client des AGFEO Dashboard genutzt werden.
<br><br>
Ebenfalls können fremde Programme über einen eingehenden Anruf oder über eine bestehende Verbindung etc. informiert werden. Die PC-Variante des AGFEO Dashboard ist hierzu in der Lage bereits über 150 Softwareprodukte zu steuern bzw. über diesen Ruf zu informieren.
<br><br>
Auch wird fremden Programmen unter MS Windows und Apple MacOS über einen systemweiten protocol handle ermöglicht eine abgehende Wahl anzustoßen, so dass Sie mit der HyperFonie auch umfassende CTI-Funktionen nutzen können.
<br><br>
(Kompatibles Softwareprogramm kundenseitig benötigt)
`,
            },
            {
              question: 'Welche Geräte können mit der HyperFonie genutzt werden?',
              answer: `
Die Kommunikation mit und über die HyperFonie ist aus Sicherheitsgründen durchgängig verschlüsselt. Somit können mit der HyperFonie nur Endgeräte eingesetzt werden, die unsere hochwertigen Verschlüsselungsverfahren unterstützen. Aus dem umfangreichen AGFEO Geräterepertoire können folgende Endgeräte auch mit der AGFEO HyperFonie eingesetzt werden: Alle Systemtelefone der AGFEO SENSORfon Reihe (ST 53 IP, ST 54 IP, ST 56 IP), alle an einer AGFEO DECT IP-Basis pro angemeldeten schnurlosen Endgeräte (DECT 33 IP, DECT 44 IP, DECT 60 IP, DECT 65 IP, DECT 70 IP, DECT 75 IP, DECT 77 IP, DECT 78 IP, DECT HeadSet IP), HeadSet Infinity, T14 SIP und die PC Variante des AGFEO Dashboard. Evtl. ist die Nutzung erst nach Einspielen aktueller Firmware- bzw. Softwarestände möglich.
<br><br>
Der Betrieb fremder SIP Geräte wird unter Beachtung der technischen Vorgaben (u.a. Verschlüsselung etc.) unterstützt. Auf Grund der Vielzahl an Herstellern, Endgerätetypen und unterschiedlichster Implementationen des SIP Protokolls kann ein sicherer Betrieb und die gebotene Funktionalität jedoch nicht gewährleistet werden. Der volle Komfort und die Nutzung aller komfortablen System-Leistungsmerkmale der HyperFonie stehen nur mit AGFEO Endgeräten zur Verfügung.
`,
            },
            {
              question: 'Was ist der maximale Ausbau (User/Gespräche) der HyperFonie?',
              answer: 'Mit der HyperFonie können derzeit maximal 250 User und 80 Gespräche gleichzeitig geführt werden.',
            },
          ]
        },
        {
          title: 'Fragen zu den HyperFonie Zusatzoptionen',
          entries: [
            {
              question: 'Was sind Zusatzoptionen?',
              answer: `
Durch die HyperFonie Funktionsflat verfügt jede HyperFonie bereits über die volle Nutzungsmöglichkeit aller im System enthaltenen Funktionen der Cloud-Telefonanlage. Die Zusatzoptionen ergänzen bei speziellem Bedarf diese Nutzungsmöglichkeiten. Jedoch nicht auf Basis einer Funktionserweiterung der HyperFonie Instanz, sondern nur um ergänzende Dienstleistungen oder periphere Systemleistungsmerkmale z.B. der Software. <br><br>
Alle Zusatzoptionen werden auf Monatsbasis gebucht und können jederzeit ganz flexibel zum jeweils nächsten Monatsende gekündigt werden.
`,
            },
            {
              question: 'Was ist die Zusatzoption „DATEV Klick“?',
              answer: `
Mit DATEV Klick kennzeichnen wir die Unterstützung der sog. „Telefonie Basis“ der DATEV e.G.. Durch diese wird eine Verbindung zwischen der sog. „DATEV Arbeitsplatz“ Software und unserem CTI/UC/VideoChat PC-Client „AGFEO Dashboard“ zur Nutzung komfortabler CTI-Funktionen im DATEV Umfeld realisiert.
<br><br>
Dies ermöglicht folgende Funktionen: <br>
<ul class="ml-4">
  <li>Direktes Anwählen von DATEV Kontakten aus dem DATEV Arbeitsplatz</li>
  <li>Übergeben von Anrufer-Informationen an den DATEV Arbeitsplatz</li>
  <li>Erstellen von Gesprächsnotizen während einer Verbindung inkl. Übergabe an DATEV</li>
  <li>Zugriff auf den zentralen Stammdatendienst (Mandanten, Institutionen etc.) von DATEV zur Nutzung der DATEV Kontakte im AGFEO Dashboard CTI/UC/VideoChat PC-Client</li>
  <li>Namentliche Identifikation des Anrufers schon beim Klingeln anhand der DATEV Kontakte</li>
</ul>
<br>
Die DATEV Klick Lösung ist gleichermaßen für lokale DATEV, als auch für DATEV.ASP Installationen im Rechenzentrum geeignet. Weitere Informationen zum AGFEO DATEV Klick auch im AGFEO TechBlog: <a href="https://techblog.agfeo.de/cti-fur-steuerberater-rechtsanwalte-und-co-der-datev-klick/" target="_blank">https://techblog.agfeo.de/cti-fur-steuerberater-rechtsanwalte-und-co-der-datev-klick/</a>
`,
            },
            {
              question: 'Was ist die Zusatzoption „Premium Support“?',
              answer: `
Als Besonderheit der HyperFonie (s.o.) steht Ihnen als HyperFonie Kunde stets ein speziell qualifizierter AGFEO Fachhandelspartner in Ihrer Region zur Seite. Dieser betreut Sie vor Ort und ist Ihr bevorzugter Ansprechpartner für alle Belange rund um Ihre HyperFonie Installation wie z.B. Einrichtung und Anpassung der Konfiguration auf Ihre persönlichen Anforderungen und Wünsche, Systemupdates, Wartung, Support der Lösung aber auch Verkauf, Installation und Einrichtung von Endgeräten etc.. Damit sind Sie stets bestens persönlich von einem qualifizierten AGFEO Fachpartner betreut.
<br><br>
Ergänzend dazu können sich HyperFonie Kunden telefonisch auch stets direkt an unseren technischen Support wenden.
<br><br>
Wird von Ihnen die Zusatzoption Premium Support zusätzlich gebucht, so können Sie unser Support-Team über eine kostengünstig anrufbare Festnetznummer inkl. Rückrufoption und ergänzend auch schriftlich direkt kontaktieren. Der Premium Support ermöglicht auch eine Fernwartung Ihrer HyperFonie Instanz durch unsere Hotline-Mitarbeiter und übernimmt eine Prüfung und kleinere Anpassungen bestehender Konfigurationspunkte. Und das mit einer garantierten Reaktionszeit von 3h innerhalb der Öffnungszeiten unseres technischen Supports/Hotline.
<br><br>
Die Vorteile Ihres Premium-Supports im Einzelnen:
<ul class="ml-4">
  <li>Telefonische und Schriftliche Erreichbarkeit des technischen Supportteams</li>
  <li>3h Reaktionszeit innerhalb Öffnungszeiten der technischen Hotline</li>
  <li>Telefonische Anrufmöglichkeit über deutsche Festnetznummer</li>
  <li>Rückrufoption</li>
  <li>Eröffnung eines Supporttickets</li>
  <li>Überprüfen eines bereits eingerichteten Leistungsmerkmals bzw. eingerichteter Funktion inkl. Änderung des bestehenden Leistungsmerkmals oder Funktion bei Bedarf</li>
  <li>Weitergehende Konfigurationserstellung / Einrichtung neuer Funktionen etc. nur separat nach Aufwand</li>
</ul>
`,
            },
            {
              question: 'Was ist die Zusatzoption „Tägliches Backup“?',
              answer: `
Ihre HyperFonie Instanz wird in der AGFEO Qualitäts Cloud gehostet. Dies sichert einen stabilen und hochverfügbaren Betrieb. Sie müssen sich auch nicht um die Wartung der Cloud kümmern, da dies von uns vorgenommen wird. Inkl. mehrfacher Absicherung Ihrer Instanz. Um den sicheren Betrieb zu gewährleisten, wird Ihre HyperFonie auch täglich durch uns gesichert.
<br><br>
Grundsätzlich können Sie uns gegen zusätzliches Entgelt auffordern eine bei uns im Rechenzentrum noch vorgehaltene Datensicherung zurückspielen zu lassen. Sollten Sie also einmal Ihre HyperFonie verkonfiguriert haben und dabei nicht selbst über eine ältere Datensicherung Ihrer HyperFonie Anlagenkonfiguration verfügen, so können wir für Sie eine noch bei uns abgespeicherte Datensicherung der gesamten Hosting-Instanz Ihrer HyperFonie kostenpflichtig zurückspielen.
<br><br>
Ohne die Zusatzoption „Tägliches Backup“ steht hierzu nur eine einzige Datensicherung vom jeweiligen Monatsanfang zur Verfügung. Mit der Zusatzoption „Tägliches Backup“ können Sie dagegen eine ganz konkrete, taggenaue Datensicherung von uns einspielen lassen. Sie erhalten also eine zusätzliche Absicherung gegen eigene Fehlkonfiguration Ihrer Cloud-Telefonanlage auf Basis unserer täglichen Datensicherung. Die Zusatzoption „Tägliches Backup“ führt also zu einer erhöhten Absicherungsoption Ihrer eigenen, individuellen Konfiguration und Anpassung der HyperFonie Cloud-Telefonanlage.
`,
            },
          ]
        },
      ],
    }
  },

  computed: {
    /**
     * Users should be able to enter a searchterm. If that's the case only
     * relevant entries should be visible. So we filter our categories and the
     * questions, answers of those based on the input.
     * Additionally we add highlights to text questions, answers so the user is
     * able do find desired parts faster. That is a bit tricky since the
     * answers may contain html-tags.
     *
     * @returns {array}
     */
    filteredCategories () {
      if (!this.search) {
        return this.categories
      }

      const search = this.search.toLowerCase()
      const highlightRegEx = new RegExp(`(${search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'ig')

      /**
       * Checks if the given faq-entry of a category matches the current search.
       *
       * @param {object} entry
       * @param {string} entry.question
       * @param {string} entry.answer
       * @returns {boolean}
       */
      const entryMatches = ({ question, answer }) => {
        // create temporary node to get rid of possible html-tags
        const div = document.createElement('div')
        div.innerHTML = `${question}${answer}`
        const text = div.innerText
        div.remove()
        return text.toLowerCase().includes(search)
      }

      /**
       * Adds a wrapping element to parts of the given text that match the
       * current search so we're able to highlight those.
       *
       * @returns {string}
       */
      const addHighlightToText = text =>
        text.replace(highlightRegEx, "<span class ='search-highlight'>$&</span>")

      return this.categories.filter(category => {
        const titleMatch = category.title.toLowerCase().includes(search)
        const entryMatch = category.entries.filter(entryMatches).length > 0
        return titleMatch || entryMatch
      }).map(({ title, entries }) => {
        return {
          title,
          entries: entries
            .filter(entryMatches)
            .map(({ question, answer }) => ({
              question: addHighlightToText(question),
              answer: addHighlightToText(answer),
            }))
        }
      })
    },
  }
}
</script>

<style lang="scss">
  .faq-view--wrap {
    .v-expansion-panel-title--active {
      color: rgb(var(--v-theme-primary));
    }

    .search-highlight {
      background-color: rgb(var(--v-theme-primary));
      color: #fff;
    }
  }
</style>
