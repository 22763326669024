<template>
  <div>
    <p>
      Geht ein Ruf auf mehreren Usern ein und wird dort nicht angenommen, so trägt sich dieser in der Anrufliste des Users ein. Nichts Besonderes, wenn dann PC-Software und AGFEO Systemtelefone dies als verpassten Anruf melden. Durchaus aber bemerkenswert, wenn dieser Ruf bei allen Usern ausgetragen wird, sobald ein anderer User diesen Anruf dann zurückgerufen hat. Sie erkennen das im AGFEO Dashboard, unserer CTI/UC/VideoChat Software am PC und als Besonderheit auch an unseren hochkomfortablen AGFEO Systemtelefonen. Die synchronisierte Anruflistenfunktion Ihrer HyperFonie Telefonanlage macht’s möglich.
    </p>
  </div>
</template>

<script>
export default {
  name: 'group-list',
}
</script>
