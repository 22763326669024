import { createRouter, createWebHashHistory } from 'vue-router'

import adminRoutes from './admin.js'
import customerRoutes from './customer.js'

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
        top: 90,
      }
    }

    return { top: 0 }
  },
  routes: [
    adminRoutes,
    customerRoutes,
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login/Index.vue')
    }
  ]
})

export default router
