<template>
  <div>
    <p class="mb-4">
      Nutzt Euren Telefonanschluss weiter! <br>
      Gut zu wissen, dass Ihr i.d.R. nicht Euren Telefonanbieter wechseln oder Eure Rufnummern portieren müsst. Denn die HyperFonie unterstützt von Haus aus über 300 SIP-Provider und -Tarife.
    </p>

    <p>
      Integriert Eure Branchensoftware/CRM! <br>
      Bindet über Eure HyperFonie Installation doch gleich auch noch Euer CRM, Warenwirtschaft, Arztpraxen- oder andere Branchesoftware an. Inkl. MS Teams, MS Outlook, DATEV etc.. Nutzt die Vorteile moderner CTI/UC Funktionen schon direkt mit Eurer eigenen Software. Es können über 150 fremde Softwareprodukte eingebunden werden. Und es werden stetig mehr.
    </p>
  </div>
</template>

<script>
export default {
  name: 'sip-software',
}
</script>
