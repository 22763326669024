import Api from './api'

export default {
  baseUrl: 'Partner',

  /**
   * searchByDistance
   *
   * @param {number} postCode
   * @param {number} distance
   * @returns {Promise}
   */
  searchByDistance (postCode, distance) {
    return fetch(`${Api.baseUrl}/${this.baseUrl}/ByPostcode/${postCode}/${distance}`, {
      headers: {
        ...Api.baseHeader,
      }
    })
  },

  /**
   * verify
   *
   * @param {string} username
   * @param {string} password
   * @returns {Promise}
   */
  verify (username = '', password = '') {
    return Api.baseFetch(`${this.baseUrl}/Verify`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
    })
  },
}
