<template>
  <div>
    <p>
      Bindet Ihr Euer kompatibles Türklingelsystem in die HyperFonie ein, so genießt den entsprechenden Komfort. Nutzt Zeitsteuerungen der Klingeltaster, steuert die unterschiedlichsten Rufziele, wendet flexible Weiterleitungen an oder profitiert vom direkten Sprechen mit auch komfortabler Tür-Öffnung auf Knopfdruck. <br>
      Nicht nur über unsere PC-Software, sondern wie es sich für uns als Systemanbieter verhält, auch über die schnurgebundenen und schnurlosen AGFEO Systemgeräte. Und zur eindeutigen akustischen Unterscheidung eines Türrufes von normalen Anrufern gibt’s an solchen AGFEO Systemgeräten selbstverständlich auch einen klaren Ding/Dong-Klingelton. <br>
      Perfekt, oder?
    </p>
  </div>
</template>

<script>
export default {
  name: 'door-bell',
}
</script>
