<template>
  <div>
    <p class="mb-4">
      Denken Sie daran: Die HyperFonie kommt vom Kommunikationsspezialisten. Wir kennen Ihre Belange und wissen, dass Sie nicht immer Zeit, Lust oder freie Ressourcen in Ihrem Unternehmen haben sich um Ihre Kommunikation selbst zu kümmern. Aber eine unpersönliche CallCenter Betreuung wird Ihren Ansprüchen auch nicht gerecht.
    </p>

    <p class="mb-4">
      Daher verfügt jede HyperVoice über den Vorteil einer persönlichen Betreuung! Zahlreiche qualifizierte AGFEO Fachhandelspartner freuen sich dazu mit Ihnen vor Ort zusammenzuarbeiten. Deren Aufgabe? Ihnen zur Seite zu stehen!
    </p>

    <p class="mb-4">
      Somit haben Sie immer jemanden in Ihrer Region, der Ihnen bei Ihrer HyperFonie hilft. Bei der Konfiguration oder Anpassung der Funktionen auf Ihre individuellen Anforderungen und Wünsche. Und selbstverständlich auch bei Integration Ihrer Branchensoftware, CRM, ERP und anderer Lösungen, wie aber auch der Installation von Endgeräten oder Integration Ihrer Gebäudeinfrastruktur wie Türklingeln und Co.
    </p>

    <p class="mb-4">
      Ihr Unternehmen ist nicht einfach nur eine Nummer eines fremden CallCenter Mitarbeiters. Wir geben Ihnen die Gewähr auf eine bestmögliche, persönliche Betreuung durch den AGFEO Fachpartner.
    </p>

    <p class="mb-4">
      HyperFonie Cloud Telefonie – sichere Unterstützung!
    </p>
  </div>
</template>

<script>
export default {
  name: 'support-info',
}
</script>
