<template>
  <v-snackbar
    ref="snackbarEl"
    v-model="visible"
    :timeout="timeout"
    :location="location"
    :color="color"
    :elevation="elevation"
  >
    {{ text }}
  </v-snackbar>
</template>

<script>
import { mapState, mapWritableState } from 'pinia'

import { useSnackbarStore } from '@/stores/snackbar'

export default {
  name: 'global-snackbar',

  computed: {
    ...mapState(useSnackbarStore, ['color', 'elevation', 'location', 'text', 'timeout']),
    ...mapWritableState(useSnackbarStore, ['visible']),
  },

  watch: {
    visible () {
      if (!this.visible) {
        // @todo: Try to get the transition-duration more flexible
        setTimeout(() => useSnackbarStore().$reset(), 150)
      }
    },
  },
}
</script>
