<template>
  <div>
    <p>
      Die HyperFonie verfügt über ein gruppenfähiges Kalendersystem mit umfassenden User-Rechtezuteilungen. Somit lassen sich selbst komplizierte Geschäftszeiten und Präsenzabläufe einfach und flexibel darstellen. Über unsere in der HyperFonie integrierten, leistungsstarken Multifunktionen können nicht nur tageweise oder nach fester Zeitvorgabe, sondern sogar auch ganz flexibel terminorientiert anhand konkreter Zeiträume einzelne oder kombinierte Systemfunktionen automatisiert gesteuert werden. Und dies nicht nur für globale Anlagenfunktionen, sondern auch für einzelne Team-Gruppen und sogar ganz individuell je einzelnem User.
    </p>
  </div>
</template>

<script>
export default {
  name: 'calendar-system',
}
</script>
