<template>
  <div>
    <p class="mb-4">
      Eure reinen Büroarbeitsplätze können in Abhängigkeit der PC-Useraktivität automatisch an/abgemeldet werden. Selbstverständlich kann zudem flexibel und individuell je User festgelegt werden, wie der jeweilige User in der HyperFonie bzgl. der einzelnen Präsenz-Zustände angesprochen und dargestellt werden soll.
    </p>

    <p class="mb-4">
      Speziell für die mobile Worker, HomeOffice & Co, wird in Abhängigkeit Eurer Präsenz auch noch gleich ganz ohne Euer Zutun das passende Endgerät im CTI/UC/VideoChat-PC-Client aktiviert. Im HomeOffice automatisch das SoftPhone nutzen, im Büro aber das Tischtelefon? It’s magic!
    </p>

    <p class="mb-4">
      Versteht es sich dann schon von selbst, dass die Präsenz oder gar andere Funktionen Eurer HyperFonie sogar durch nur einfaches Herausnehmen Eures schnurlosen AGFEO DECT IP Endgerätes aus der Ladeschale umgeschaltet werden können?
    </p>

    <p>
      Und für Alle, die solche Automatismen als allzu magisch empfinden: Manuell über Software, App oder Funktionstaste am Endgerät (natürlich inkl. LED Unterstützung) geht selbstverständlich auch.
    </p>
  </div>
</template>

<script>
export default {
  name: 'presence-control',
}
</script>
