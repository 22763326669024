<template>
  <div>
    <p>
      Vergesst das Anlegen komplizierter Regelwerke. Mit der Wahlregel Funktion der HyperFonie erhaltet Ihr ein äußerst flexibles Konfigurationstool, um bei abgehenden Rufen stets die korrekt Rufnummer zu senden. Selbst bei Rückrufen eines Users wird die vom Anrufer zuvor benutzte Amtslinie verwendet, so dass im Zweifelsfall nicht dessen eigene Durchwahl, sondern die Durchwahl der z.B. Vertriebsgruppe gesendet wird. Ersetzen von Absenderkennungen, Senden von Clip no Screening, DDI-Durchwahlen oder Team- bzw. Gruppen-Nummern, Sperren von Nummernkreisen und noch Vieles mehr sind stets ganz leicht steuerbar. Die Beeinflussung solcher Funktionen geschieht dabei ganz komfortabel, bequem und ohne kompliziertes Regelwerk. Natürlich nicht nur jederzeit über PC-Software, sondern selbst über die AGFEO Systemtelefone sind solche Funktionen problemlos nicht nur steuerbar, sondern können darüber auch visualisiert werden.
    </p>
  </div>
</template>

<script>
export default {
  name: 'call-settings',
}
</script>
