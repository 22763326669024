<template>
  <div class="fancy-bg--wrap">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:svgjs="http://svgjs.com/svgjs"
      preserveAspectRatio="none"
      viewBox="0 0 2000 800"
    >
      <g>
        <path d="M0 0L364.41 0L0 370.11z" fill="rgba(255, 255, 255, .1)" />
        <path d="M0 370.11L364.41 0L1216.56 0L0 536.95z" fill="rgba(255, 255, 255, .075)" />
        <path d="M0 536.95L1216.56 0L1338.35 0L0 561.1400000000001z" fill="rgba(255, 255, 255, .05)" />
        <path d="M0 561.1400000000001L1338.35 0L1714.1699999999998 0L0 641.0400000000001z" fill="rgba(255, 255, 255, .025)" />
        <path d="M2000 800L1230.1399999999999 800L2000 416.33z" fill="rgba(0, 0, 0, .1)" />
        <path d="M2000 416.33L1230.1399999999999 800L555.2199999999999 800L2000 221.82z" fill="rgba(0, 0, 0, .075)" />
        <path d="M2000 221.81999999999994L555.22 800L358.95000000000005 800L2000 154.31999999999994z" fill="rgba(0, 0, 0, .05)" />
        <path d="M2000 154.31999999999994L358.95000000000005 800L305.24000000000007 800L2000 45.679999999999936z" fill="rgba(0, 0, 0, .025)" />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'fancy-bg'
}
</script>

<style lang="scss">
  .fancy-bg--wrap {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    overflow: hidden;
    opacity: 0.5;
    pointer-events: none;

    svg {
      width: 100%;
      height: 100%;
    }
  }
</style>
