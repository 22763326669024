<template>
  <div>
    <p>
      Bestimmte Anrufer umleiten? Das Handy mitklingeln lassen? Nur das SoftPhone anstelle Tischappart signalisieren lassen? VIP-Anrufer die DND Funktion durchbrechen lassen? Alles möglich und noch viel mehr. Gleichzeitig steuern die User-Profile die Präsenz, so dass andere User stets bestens über die Erreichbarkeit des Users informiert sind. Textinfo „Bis 14h im Meeting“? Geht! Und das nicht nur als Anzeige in der Software. Sondern natürlich auch mit Anzeige an den AGFEO Systemgeräten. Wir sind ja schließlich anders als andere ein Systemanbieter und Vollsortimentler mit eigenen Endgeräten.
    </p>
  </div>
</template>

<script>
export default {
  name: 'user-profiles',
}
</script>
