<template>
  <div class="about-view--wrap flex-grow-1">
    <div class="main-bg-gradient_">
      <v-container class="py-8 py-sm-15">
        <v-sheet class="pa-8 pa-sm-15 mb-15" rounded="xl">
          <hyper-heading
            title="Topqualität seit 1947"
            class="mb-8 mb-sm-15"
          >
            <template #subtitle>
              <agfeo-logo
                class="mx-auto mx-sm-0"
                fill="#252525"
              />
            </template>
          </hyper-heading>

          <div class="text-h6 font-weight-regular mb-10 text-center text-md-left">
            Seit dem Gründungsjahr 1947 entwickelt AGFEO Lösungen, die die Kommunikation besser und einfacher machen.
            Permanenter Wandel und hohe Innovationskraft sind das Erfolgsrezept. Moderne Produkte und Organisationsstrukturen stehen für wirtschaftliche und ressourcenschonende Lösungen. Die Kunden und ihre Bedürfnisse stehen dabei im Fokus des Handelns und sind der Ansporn, jeden Tag besser zu sein. Neben den verlässlichen Branchenlösungen entstanden und entstehen ständig neue Anwendungsmöglichkeiten.
          </div>

          <p class="font-weight-bold text-h6 text-primary text-center text-md-left">
            AGFEO – einfach | perfekt | kommunizieren
          </p>
        </v-sheet>

        <v-card
          elevation="0"
          color="primary"
          rounded="xl"
          class="mb-15"
        >
          <v-row no-gutters>
            <v-col :cols="12" :md="6">
              <v-img
                src="/images/logo-systemgedanke.jpg"
                width="100%"
                height="100%"
                max-height="380px"
                cover
              />
            </v-col>
            <v-col :cols="12" :md="6" class="d-flex flex-column">
              <div class="text-center d-flex flex-column justify-center pa-10 align-center flex-grow-1">
                <div class="mb-4 text-h4 font-weight-bold font-exo">
                  Der AGFEO Systemgedanke 4.0
                </div>
                <div class="text-h6 font-weight-regular">
                  <p class="mb-4">
                    Wir verbinden Cloud- und ITK-Systeme, Endgeräte und Software.
                  </p>
                  <p>
                    Warum? Weil wir es als Vollsortimentler können, denn unsere in Bielefeld entwickelten Produkte sind perfekt
                    aufeinander abgestimmt.
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>

        <v-sheet class="pa-8 pa-sm-15 mb-15" rounded="xl">
          <hyper-heading
            title="Unsere Grundsätze"
            subtitle="Das zeichnet uns aus"
            class="mb-8 mb-sm-15"
          />
          <company-differentiation />
        </v-sheet>

        <v-card rounded="xl" :elevation="0">
          <video
            src="https://agfeo.de/wp-content/uploads/2024/04/HF_Clip_webseite.mp4"
            title="AGFEO HyperFonie - Cloudtelefonie mit System"
            controls
            poster="/images/hf_video_poster.webp"
          />
        </v-card>
      </v-container>
    </div>
  </div>
</template>

<script>
import AgfeoLogo from '@/components/icons/AgfeoLogo.vue'
import CompanyDifferentiation from '@/components/CompanyDifferentiation.vue'
import HyperHeading from '@/components/HyperHeading.vue'

export default {
  name: 'about-view',

  components: {
    AgfeoLogo,
    CompanyDifferentiation,
    HyperHeading,
  },
}
</script>

<style lang="scss">
  .about-view--wrap {
    .agfeo-logo--wrap {
      width: 180px;
      max-width: 90%;
    }

    video {
      display: block;
      width: 100%;
      aspect-ratio: 16 / 9;
    }
  }
</style>
