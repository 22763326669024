<template>
  <div>
    <p>
      Anwender der HyperFonie profitieren durch unsere langjährige Erfahrung und Expertise im Kommunikationsbereich seit 1947!
    </p>

    <ul class="my-4">
      <li>
        Überragende Systemmerkmale für bestmöglich abgestimmte Bedien-, Visualisierungs- und Funktionseigenschaften
      </li>
      <li>
        Einfachste Bedienung, höchster Komfort
      </li>
      <li>
        Umfassendste CTI/UC-Möglichkeiten durch unser AGFEO Dashboard. Der CTI/UC/VideoChat-Software für MS Windows, MacOS und optional Linux
      </li>
      <li>
        Für Sie bedeutet das: <br>
        MEHR Funktionen, <br>
        MEHR Komfort, bei gleichzeitig <br>
        EINFACHERE Bedienung
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'agfeo-system',
}
</script>
