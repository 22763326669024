<template>
  <div class="plan-prices--wrap text-center text-lg-left">
    <div class="text-h5 text-lg-center px-4 py-lg-4">
      Mtl. Preis / User:
      <span class="font-weight-bold">
        {{ readablePrice(pricePerUser) }}&nbsp;€
      </span>
    </div>

    <div class="px-4 pb-4 text-h6 font-weight-regular font-exo flex-grow-1 d-flex flex-column text-center">
      <div v-if="additionalOptionsPrice > 0">
        Zusatzoptionen: {{ readablePrice(additionalOptionsPrice) }}&nbsp;€
      </div>

      <div>
        <template v-if="plan.referencePlan && yearlySavings">
          <div class="font-weight-bold">
            Sie sparen
            {{ readablePrice(yearlySavings) }}&nbsp;€
            jährlich
          </div>
          <div class="text-subtitle-2 font-weight-light mt-n1">
            gegenüber Laufzeit 1 Monat
          </div>

          <v-btn
            variant="outlined"
            size="small"
            class="mt-4"
            @click="totalPriceVisible = !totalPriceVisible"
          >
            {{ totalPriceVisible ? 'Gesamtpreis ausblenden' : 'Gesamtpreis anzeigen' }}
          </v-btn>

          <v-expand-transition>
            <div v-if="totalPriceVisible">
              <div class="pt-3">
                Gesamt: {{ readablePrice(
                  plan.prices.yearly ? monthlyPrice * 12 : monthlyPrice
                ) }}&nbsp;€

                {{ plan.prices.yearly ? 'jährlich' : 'mtl.' }}

                <div v-if="plan.prices.yearly" class="mt-n2">
                  entspricht {{ readablePrice(monthlyPrice) }}&nbsp;€ mtl.
                </div>

                <div class="text-subtitle-2 mt-n1 font-weight-light">
                  zzgl. gesetzl. MwSt
                </div>
              </div>
            </div>
          </v-expand-transition>
        </template>
        <template v-else>
          Gesamt: {{ readablePrice(
            plan.prices.yearly ? monthlyPrice * 12 : monthlyPrice
          ) }}&nbsp;€

          {{ plan.prices.yearly ? 'jährlich' : 'mtl.' }}

          <div class="text-subtitle-2 mt-n1 font-weight-light">
            zzgl. gesetzl. MwSt
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'plan-prices',

  props: {
    enteredUsers: {
      type: Number,
      default: 0,
    },

    additionalOptionsPrice: {
      type: Number,
      default: 0,
    },

    plan: {
      type: Object,
      required: true,
    },
  },

  data () {
    return {
      totalPriceVisible: false,
    }
  },

  computed: {
    /**
     * @returns {number}
     */
    pricePerUser () {
      return this.getPricePerUser(this.plan)
    },

    /**
     * monthlyPrice
     *
     * @returns {number}
     */
    monthlyPrice () {
      return this.getMonthlyPrice(this.plan)
    },

    yearlySavings () {
      if (!this.plan.referencePlan) {
        return null
      }

      return 12 * this.getMonthlyPrice(this.plan.referencePlan) - 12 * this.monthlyPrice
    },
  },

  methods: {
    /**
     * Prices should always use two decimal-places and the german separator.
     *
     * @param {number} price
     * @returns {string}
     */
    readablePrice (price) {
      return price.toFixed(2).replace('.', ',')
    },

    /**
     * getMonthlyPrice
     *
     * @returns {number}
     */
    getMonthlyPrice (plan) {
      return this.getPricePerUser(plan) * this.enteredUsers + this.additionalOptionsPrice
    },

    /**
     * Every tariff has multiple prices based on the entered users.
     *
     * @returns {number}
     */
    getPricePerUser (plan) {
      const lowerLimits = Object.keys(plan.prices.perUser)
        .map(k => parseInt(k))
        .sort((a, b) => a - b)

      const relevantPrices = lowerLimits.filter(lowerLimit => this.enteredUsers >= lowerLimit)

      return plan.prices.perUser[relevantPrices.length > 0
        ? relevantPrices[relevantPrices.length - 1]
        : lowerLimits[0]
      ]
    },
  }
}
</script>
