<template>
  <div class="customer-index--wrap flex-grow-1 d-flex flex-column">
    <app-header v-if="!isLoginView" />

    <v-main class="d-flex flex-column">
      <RouterView />
    </v-main>

    <app-footer v-if="!isLoginView" />
  </div>
</template>

<script>
import AppFooter from '@/views/Customer/AppFooter.vue'
import AppHeader from '@/views/Customer/AppHeader.vue'

export default {
  name: 'customer-index',

  components: {
    AppFooter,
    AppHeader,
  },

  computed: {
    isLoginView () {
      return this.$route.name === 'login'
    },
  },

  mounted () {
    localStorage.setItem('distributor-ref', this.$route?.query?.ref || '')
  },
}
</script>
